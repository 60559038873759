import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icons } from 'components';
import {
  updateTaskboxV1TaskboxesTaskboxIdPut,
  updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut,
  readAutocompleteListV1AutocompleteGet,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  createTaskboxV1TaskboxesPost,
  createWorkboxV2WorkboxesPost,
  mergeWorkOrWorkBoxesV2V2WorksMergePost,
  removeTaskboxesV1TaskboxesDelete,
  updateBulkTaskboxesV1TaskboxesBulkPut,
  assignTasksV1TaskboxesTaskboxIdAssignPost,
  createCategoryV1CategoryPost,
  updateCategoryV1CategoryCategoryIdPatch,
  deleteCategoryV1CategoryCategoryIdDelete,
  assignTasksToProjectV1ProjectProjectIdAssigningTaskPost,
  getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet,
} from 'queries';
import {
  OutFrequentWork,
  OutTaskboxDetailResponse,
  CreateTaskbox,
  UpdateTaskbox,
  OutAutoComplete,
  OutTaskboxDetail,
  RemoveTaskboxesV1TaskboxesDeleteParams,
  UpdateBulkTaskbox,
  OutTaskboxDetailResponseForSubtask,
  CreateTaskboxTask,
  UpdateTaskForTaskBox,
  OutCategory,
  OutProject,
} from 'queries/model';
import React, { useRef, useEffect, useState, forwardRef, ForwardedRef, RefObject, useImperativeHandle, useMemo, useLayoutEffect, useCallback } from 'react';
import { COLORS } from 'styles/constants';
import { TimelineTaskbox } from './TimelineTaskbox';
import { DragDropContext, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';
import Fonts from 'components/Fonts';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { dragContextAtom } from 'atoms/works';
import { nowAtom } from 'atoms/now';
import dayjs, { Dayjs } from 'lib/dayjs';
import { DATE_FORMAT_4, DATE_FORMAT_5 } from 'utils/datetimeFormat';
import toast from 'react-hot-toast';
import { IssueCheckBox, SubTaskCollapse, SubTaskExpand } from 'components/Icons';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import TaskBlockInputList, { TaskBlock, TaskBlockHandle } from './TaskBlockInputList';
import { debounce } from 'lodash';
import { TaskboxIncompleteDetail } from './TaskboxIncompleteDetail';
import { tasksAtom } from 'atoms/tasks';
import { TimerActionType } from '..';
import { MultiSelectType } from 'pages/Ritual/Organize';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { DeadlinePopover } from './DeadlinePopover';
import { userStateAtom } from 'atoms/userState';
import CreateListViewTaskbox, { ListViewTaskboxHandle } from './CreateListViewTaskbox';
import { projectFeatureAtom, projectsAtom } from 'atoms/projects';
import { MultiSelectSubtaskIds } from '../Today';
import { InboxContextMenuType } from 'components/InboxContextMenu';
import ProjectListPopover from './ProjectListPopover';
import { t } from 'i18next';
import { languageAtom } from 'atoms/language';
import { on } from '@react-hookz/web/cjs/util/misc';

export interface Props {
  events?: OutTaskboxDetailResponse[];
  entireBoardTaskboxes?: OutTaskboxDetailResponse[];
  date?: Date;
  expanded?: boolean;
  selectedEvent?: OutTaskboxDetailResponse;
  selectedSubtaskId?: string;
  editing?: boolean;
  isHeaderInViewport?: boolean;
  focusedTaskBoard?: boolean;
  incompleteTaskboxList?: OutAutoComplete[];
  templateList?: OutFrequentWork[];
  multiSelectIds?: string[];
  multiSelectAnchorEl?: HTMLElement | null;
  categoryList?: OutCategory[];
  multiSelectSubtaskIds?: MultiSelectSubtaskIds[];
  multiSelectSubtaskAnchorEl?: HTMLElement | null;
  onChange?: (startDate: Date, endDate?: Date, move?: boolean) => void;
  onChangeWithoutFetchData?: (taskbox: OutTaskboxDetailResponse) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onClickSubtask?: (id: string) => void;
  onCreateTaskbox?: (taskbox: CreateTaskbox) => void;
  onDropTask?: (date: Date) => void;
  onClickFocusInput?: () => void;
  onClickExpand?: (date: Date) => void;
  onClickEditTaskBoard?: (date: Date) => void;
  onFetchIncompleteTaskboxes?: (title?: string) => void;
  onFetchTemplateList?: () => void;
  onChangeMultiSelectIds?: (id: string, e?: React.MouseEvent<HTMLDivElement>) => void;
  onChangeMultiSelectList?: (id: string[]) => void;
  onCloseMultiSelect?: () => void;
  onMultiSelectAnchorElNull?: () => void;
  onChangeTimer?: (type: TimerActionType, time?: Date) => void;
  onBeautifulDndDrop?: (date: Date, taskboxes: OutTaskboxDetailResponse[]) => void;
  onFetchCategoryList?: () => void;
  onUpdateTaskboardFromListView?: (date: string, taskboxes: OutTaskboxDetailResponse[]) => void;
  onContextMenuTaskbox?: (id: string) => void;
  onChangeMultiSelectSubtaskIds?: (subtaskList: MultiSelectSubtaskIds[], e?: React.MouseEvent<HTMLDivElement>) => void;
  onClickMultiSelectSubtaskAction?: (type: InboxContextMenuType, data: any) => void;
  onCloseMultiSelectSubtask?: () => void;
  onMultiSelectSubtaskAnchorElNull?: () => void;
}

export const TimelineView = forwardRef(
  (
    {
      events = [],
      entireBoardTaskboxes = [],
      date,
      selectedEvent,
      selectedSubtaskId,
      expanded = true,
      editing,
      isHeaderInViewport,
      focusedTaskBoard,
      incompleteTaskboxList = [],
      templateList = [],
      multiSelectIds = [],
      multiSelectAnchorEl,
      categoryList = [],
      multiSelectSubtaskIds = [],
      multiSelectSubtaskAnchorEl,
      onChange = () => {},
      onChangeWithoutFetchData = () => {},
      onClickTaskbox = () => {},
      onClickSubtask = () => {},
      onCreateTaskbox = () => {},
      onDropTask = () => {},
      onClickFocusInput = () => {},
      onClickExpand = () => {},
      onClickEditTaskBoard = () => {},
      onFetchIncompleteTaskboxes = () => {},
      onFetchTemplateList = () => {},
      onChangeMultiSelectIds = () => {},
      onChangeMultiSelectList = () => {},
      onCloseMultiSelect = () => {},
      onMultiSelectAnchorElNull = () => {},
      onChangeTimer = () => {},
      onBeautifulDndDrop = () => {},
      onFetchCategoryList = () => {},
      onUpdateTaskboardFromListView = () => {},
      onContextMenuTaskbox = () => {},
      onChangeMultiSelectSubtaskIds = () => {},
      onClickMultiSelectSubtaskAction = () => {},
      onCloseMultiSelectSubtask = () => {},
      onMultiSelectSubtaskAnchorElNull = () => {},
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [timelineItems, setTimelineItems] = useState<OutTaskboxDetailResponse[]>(events);
    const [taskboxImage, setTaskboxImage] = useState<OutTaskboxDetailResponse>();
    const [incomplete, setIncomplete] = useState<HTMLElement | null>(null);
    const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteWorkList[]>([]);
    const [dropStatus, setDropStatus] = useState(false);
    const [beautifulDrag, setBeautifulDrag] = useState(false);
    const [isDragOver, setDragOver] = useState(false);
    const [isCreateListViewTaskbox, setIsCreateListViewTaskbox] = useState(false);
    const [currentDate] = useAtom(nowAtom);
    const [taskViewDragContext, setTaskViewDragContext] = useAtom(dragContextAtom);
    const [, fetchTasks] = useAtom(tasksAtom);
    const [, fetchProjects] = useAtom(projectsAtom);
    const [userState] = useAtom(userStateAtom);
    const [language] = useAtom(languageAtom);
    const isDragging = useMemo(
      () =>
        taskViewDragContext &&
        [
          'task',
          'taskbox',
          'multi-taskbox',
          'SOMEDAY_TASK',
          'project',
          'instance',
          'project-subtask',
          'subtask',
          'multi-subtask',
          'project-multi-subtask',
        ].includes(taskViewDragContext.type!) &&
        taskViewDragContext.view !== 'onboarding',
      [taskViewDragContext],
    );
    const refInput = useRef<HTMLDivElement>(null);
    const refTaskboard = useRef<HTMLDivElement>(null);
    const refTodoScroll = useRef<HTMLDivElement[] | null[]>([]);
    const refTimelineScroll = useRef<HTMLDivElement[] | null[]>([]);
    const refListViewTaskbox = useRef<ListViewTaskboxHandle>(null);

    useEffect(() => {
      setTimelineItems(events);
    }, [events]);

    useEffect(() => {
      const refTaskboardView = ref as RefObject<HTMLDivElement>;
      if (!refTaskboardView || !refTaskboardView.current || !selectedEvent) {
        return;
      }

      const targetTaskbox = events.find((event) => event.id === selectedEvent.id);
      if (targetTaskbox && dayjs(currentDate).isSame(date)) {
        const index = targetTaskbox.allDay
          ? events.filter((event) => event.allDay).findIndex((event) => event.id === selectedEvent.id)
          : events.filter((event) => !event.allDay).findIndex((event) => event.id === selectedEvent.id);

        const scrollRef = targetTaskbox.allDay ? refTodoScroll : refTimelineScroll;
        setTimeout(() => {
          const el = scrollRef.current[index];
          if (el) {
            refTaskboardView.current?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
          }
        }, 100);
      }
    }, [selectedEvent]);

    useKeyboardEvent(
      true,
      (ev) => {
        if (ev.shiftKey && ev.code === 'Slash') {
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }

          if (dayjs(currentDate).isSame(date)) {
            handleCreateListViewTaskbox();
          }
        }
      },
      [],
      { eventOptions: { passive: false } },
    );

    const handleBeautifulDragStart = (start: DragStart) => {
      const targetTaskbox = timelineItems.find((item) => item.id === start.draggableId);
      if (targetTaskbox?.type === 'TERM_TASK') {
        language === 'ko' ? toast.error('종료일이 지정된 태스크박스는 순서를 변경할 수 없어요.') : toast.error(`Taskbox with end date can't be reordered.`);
        // toast.error(`Taskbox with end date can't be reordered.`);
        return;
      }
      setBeautifulDrag(true);
    };

    const handleBeautifulDragEnd = async (result: DropResult) => {
      if (!result.destination) return;
      if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;
      if (result.source.droppableId === 'all-taskbox') {
        if (timelineItems[result.source.index].type === 'TERM_TASK') return;
        if (timelineItems[result.destination.index].type === 'TERM_TASK') {
          language === 'ko' ? toast.error('종료일이 지정된 태스크박스는 순서를 변경할 수 없어요.') : toast.error(`Taskbox with end date can't be reordered.`);
          // toast.error(`Taskbox with end date can't be reordered.`);
          return;
        }

        const newTaskbox = timelineItems.filter((item) => item.id !== timelineItems[result.source.index].id);
        newTaskbox.splice(result.destination.index, 0, timelineItems[result.source.index]);
        onBeautifulDndDrop(date!, newTaskbox);
        setTimelineItems([...newTaskbox]);

        const updateTaskbox = timelineItems[result.source.index];
        if (result.source.index < result.destination.index) {
          const beforeId = timelineItems[result.destination.index].id;
          await updateTaskboxV1TaskboxesTaskboxIdPut(updateTaskbox.id!, { beforeId });
        } else {
          const beforeId = result.destination.index - 1 < 0 ? null : timelineItems[result.destination.index - 1].id;
          await updateTaskboxV1TaskboxesTaskboxIdPut(updateTaskbox.id!, { beforeId });
        }
        return;
      }

      const startTaskboxIndex = timelineItems.findIndex((item) => item.id === result.source.droppableId);
      const startTaskBox = timelineItems.find((item) => item.id === result.source.droppableId);
      const endTaskboxIndex = timelineItems.findIndex((item) => item.id === result.destination!.droppableId);
      const endTaskBox = timelineItems.find((item) => item.id === result.destination!.droppableId);

      if (startTaskBox && endTaskBox) {
        if (startTaskBox === endTaskBox) {
          const srcTask = startTaskBox.tasks!.find((item) => item.id === result.draggableId);
          if (!srcTask) return;

          const dstTask = startTaskBox.tasks![result.destination.index];
          if (!dstTask) return;

          const srcTaskIndex = startTaskBox.tasks!.findIndex((item) => item.id === srcTask.id);
          if (srcTaskIndex === -1) return;
          const dstTaskIndex = startTaskBox.tasks!.findIndex((item) => item.id === dstTask.id);
          if (dstTaskIndex === -1) return;

          const newTasks = startTaskBox.tasks!.filter((item) => item.id !== srcTask.id);
          newTasks.splice(dstTaskIndex, 0, srcTask);

          startTaskBox.tasks = newTasks;
          timelineItems[startTaskboxIndex] = startTaskBox;
          onBeautifulDndDrop(date!, timelineItems);
          // setTimelineItems([...timelineItems]);

          if (srcTaskIndex < dstTaskIndex) {
            await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(startTaskBox.id!, srcTask.id!, { ...srcTask, beforeId: dstTask.id });
          } else {
            const id = dstTaskIndex - 1 >= 0 ? startTaskBox.tasks[dstTaskIndex - 1].id : null;
            await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(startTaskBox.id!, srcTask.id!, { ...srcTask, beforeId: id });
          }
        } else {
          const srcTask = startTaskBox.tasks!.find((item) => item.id === result.draggableId);
          if (!srcTask) return;

          const startNewTasks = startTaskBox.tasks!.filter((item) => item.id !== srcTask.id);
          startTaskBox.tasks = startNewTasks;
          events[startTaskboxIndex] = startTaskBox;

          const endNewTasks = endTaskBox.tasks;
          endNewTasks!.splice(result.destination.index, 0, srcTask);

          endTaskBox.tasks = endNewTasks;
          events[endTaskboxIndex] = endTaskBox;

          onBeautifulDndDrop(date!, timelineItems);

          const id = result.destination.index - 1 < 0 ? null : endTaskBox.tasks![result.destination.index - 1].id;
          await assignTasksV1TaskboxesTaskboxIdAssignPost(endTaskBox.id!, { beforeId: id, tasks: [srcTask.id!] });
          if (srcTask.done === true) {
            endTaskBox.tasks![result.destination.index].done = true;
            await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(
              endTaskBox.id!,
              endTaskBox.tasks![result.destination.index].id!,
              endTaskBox.tasks![result.destination.index],
            );
          }
        }
      }

      setTimeout(() => {
        setBeautifulDrag(false);
      }, 100);
    };

    const handleChangeSubtask = (id: string, tasks: OutTaskboxDetailResponseForSubtask[]) => {
      const index = timelineItems.findIndex((item) => item.id === id);
      timelineItems[index].tasks = tasks;
      onBeautifulDndDrop(date!, timelineItems);
    };

    const handleDrop = async () => {
      if (
        taskViewDragContext &&
        [
          'task',
          'taskbox',
          'multi-taskbox',
          'SOMEDAY_TASK',
          'project',
          'instance',
          'project-subtask',
          'subtask',
          'multi-subtask',
          'project-multi-subtask',
        ].includes(taskViewDragContext.type!)
      ) {
        setDragOver(false);
        setDropStatus(false);
        onDropTask && onDropTask(date!);
      }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(true);
    };

    const handleChangeTaskboxImage = (event: OutTaskboxDetailResponse) => {
      setTaskboxImage(event);
    };

    const handleClickFocusPlusIcon = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClickFocusInput();
      refInput.current?.focus();
    };

    const handleOpenIncompleteDialog = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setIncomplete(event.currentTarget);
    };

    const handleCloseIncompleteDialog = () => {
      setIncomplete(null);
    };

    /** 미완료 태스크박스 오늘로 옮기기 */
    const handleClickTomorrow = async (incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      setIncomplete(null);

      const newDate = dayjs(date).isToday() ? dayjs().add(1, 'day').format(DATE_FORMAT_4) : dayjs().format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      const apiRequests = incompleteTaskboxes.map(async (item) => {
        if (item.tasks!.filter((task) => task.done === true).length! > 0) {
          const completeTasks = item.tasks!.filter((task) => task.done === true);
          const incompleteTasks = item.tasks!.filter((task) => task.done === false);
          const newTasks = incompleteTasks.map((task) => ({ ...task, id: uuidv4() }));
          const createTaskbox: CreateTaskbox = {
            id: uuidv4(),
            title: item.title!,
            start: { date: newDate },
            end: { date: newDate },
            tasks: [...newTasks] as CreateTaskboxTask[],
          };
          await Promise.all([
            updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, { tasks: completeTasks as UpdateTaskForTaskBox[], done: true }),
            createTaskboxV1TaskboxesPost(createTaskbox),
          ]);
        } else {
          await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
        }
      });
      await Promise.all(apiRequests);
      onChange(dayjs(newDate).toDate());
      onChange(date!);
      language === 'ko' ? toast.success('미완료 업무들을 오늘로 옮겼습니다.') : toast.success('Moved incompleted tasks to today.');
      // toast.success('Moved incompleted tasks to today.');
    };

    /** 미완료 태스크박스 다음주로 옮기기 */
    const handleClickNextWeek = async (incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      setIncomplete(null);
      const newDate = dayjs(date).add(1, 'week').day(1).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      const apiRequests = incompleteTaskboxes.map(async (item) => {
        if (item.tasks!.filter((task) => task.done === true).length! > 0) {
          const completeTasks = item.tasks!.filter((task) => task.done === true);
          const incompleteTasks = item.tasks!.filter((task) => task.done === false);
          const newTasks = incompleteTasks.map((task) => ({ ...task, id: uuidv4() }));
          const createTaskbox: CreateTaskbox = {
            id: uuidv4(),
            title: item.title!,
            start: { date: newDate },
            end: { date: newDate },
            tasks: [...newTasks] as CreateTaskboxTask[],
          };
          await Promise.all([
            updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, { tasks: completeTasks as UpdateTaskForTaskBox[], done: true }),
            createTaskboxV1TaskboxesPost(createTaskbox),
          ]);
        } else {
          await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
        }
      });
      await Promise.all(apiRequests);
      onChange(dayjs(newDate).toDate());
      onChange(date!);
      language === 'ko' ? toast.success('미완료 업무들을 다음주 월요일로 옮겼습니다.') : toast.success('Moved incompleted tasks to next Monday.');
      // toast.success('Moved incompleted tasks to next Monday.');
    };

    /** 미완료 태스크박스 나중에로 옮기기 */
    const handleClickLater = async (incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      setIncomplete(null);
      const updateTaskbox: UpdateTaskbox = { workSectionType: 'someday' };
      const apiRequests = incompleteTaskboxes.map(async (item) => {
        if (item.tasks!.filter((task) => task.done === true).length! > 0) {
          const completeTasks = item.tasks!.filter((task) => task.done === true);
          const incompleteTasks = item.tasks!.filter((task) => task.done === false);
          const newTasks = incompleteTasks.map((task) => ({ ...task, id: uuidv4() }));
          const createWorkBoxes: CreateTaskbox = {
            id: uuidv4(),
            title: item.title!,
            tasks: [...newTasks] as CreateTaskboxTask[],
            categoryId: item.category ? [item.category.id] : [],
            deadline: item.deadline,
          };
          await Promise.all([
            updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, { tasks: completeTasks as UpdateTaskForTaskBox[], done: true }),
            createWorkboxV2WorkboxesPost(createWorkBoxes),
          ]);
        } else {
          await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
        }
      });
      await Promise.all(apiRequests);
      onChange(date!);
      fetchTasks();
      // toast.success('미완료 업무를 나중에로 옮겼습니다.');
      toast.success('Moved incompleted tasks to Later.');
    };

    /** 미완료 태스크박스 특정 일자로 미루는 로직 */
    const handleClickCalendar = async (value: Dayjs, incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      setIncomplete(null);
      if (!dayjs(date).isBefore(dayjs(value), 'day')) {
        language === 'ko' ? toast.error(`과거로는 옮길 수 없습니다.`) : toast.error(`Can't move to the past.`);
        // toast.error(`Can't move to the past.`);
        return;
      }
      const newDate = dayjs(value).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      const apiRequests = incompleteTaskboxes.map(async (item) => {
        if (item.tasks!.filter((task) => task.done === true).length! > 0) {
          const completeTasks = item.tasks!.filter((task) => task.done === true);
          const incompleteTasks = item.tasks!.filter((task) => task.done === false);
          const newTasks = incompleteTasks.map((task) => ({ ...task, id: uuidv4() }));
          const createTaskbox: CreateTaskbox = {
            id: uuidv4(),
            title: item.title!,
            start: { date: newDate },
            end: { date: newDate },
            tasks: [...newTasks] as CreateTaskboxTask[],
          };
          await Promise.all([
            updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, { tasks: completeTasks as UpdateTaskForTaskBox[], done: true }),
            createTaskboxV1TaskboxesPost(createTaskbox),
          ]);
        } else {
          await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
        }
      });
      await Promise.all(apiRequests);
      onChange(dayjs(newDate).toDate());
      onChange(date!);
      language === 'ko'
        ? toast.success(`미완료 업무들를 ${dayjs(newDate).format(DATE_FORMAT_5)}로 옮겼습니다.`)
        : toast.success(`Moved incompleted tasks to ${dayjs(newDate).format(DATE_FORMAT_5)}.`);
      // toast.success(`Moved incompleted tasks to ${dayjs(newDate).format(DATE_FORMAT_5)}.`);
    };

    //오토컴플릿
    const fetchAutoCompleteList = async (title?: string) => {
      const param = title ? { title } : undefined;
      const data: OutAutoComplete[] = await readAutocompleteListV1AutocompleteGet(param);

      const incompleteWorkList = data
        .filter((item) => item.done === false && dayjs(item.start?.date || item.start?.datetime).isBefore(dayjs(date).subtract(1, 'day')))
        .map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_5) }))
        .sort((a, b) => (a.date > b.date ? -1 : 1));
      setAutoCompleteList([...incompleteWorkList]);
    };

    const handleTaskBoardDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      if (editing) return;
      if (events.length > 0) return;
      setDropStatus(true);
    };

    const handleTaskBoardDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      if (events.length > 0) return;
      if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
      setDropStatus(false);
    };

    const handleClickMultiSelectActions = (type: MultiSelectType, date?: Dayjs) => {
      switch (type) {
        case 'TOMORROW':
          handleClickMultiSelectTomorrow();
          break;
        case 'NEXT_WEEK':
          handleClickMultiSelectNextWeek();
          break;
        case 'LATER':
          handleClickMultiSelectLater();
          break;
        case 'CALENDAR':
          handleClickMultiSelectCalendar(date!);
          break;
        case 'DELETE':
          handleClickMultiSelectDelete();
          break;
        case 'MERGE':
          handleClickMultiSelectMerge();
          break;
      }
    };

    /** 멀티 셀렉된 태스크박스 다음날로 옮기기 */
    const handleClickMultiSelectTomorrow = async () => {
      onCloseMultiSelect();
      const formattedDate = dayjs(date).format(DATE_FORMAT_4);
      const newDate = dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4);
      const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectIds.map((id) => {
        return { id, start: { date: newDate }, end: { date: newDate } };
      });

      await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);

      onChange && onChange(date!);
      await new Promise((resolve) => setTimeout(resolve, 100));
      onChange && onChange(dayjs(date).add(1, 'day').toDate());
      if (dayjs(date).format(DATE_FORMAT_4) < dayjs().format(DATE_FORMAT_4)) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      language === 'ko' ? toast.success('선택된 태스크박스를 다음 날로 옮겼습니다.') : toast.success('Moved selected taskboxes to the next day.');
      // toast.success('Moved selected taskboxes to the next day.');
    };

    /** 멀티 셀렉된 태스크박스 다음주로 옮기기 */
    const handleClickMultiSelectNextWeek = async () => {
      onCloseMultiSelect();
      const newDate = dayjs(date).add(1, 'week').day(1).format(DATE_FORMAT_4);
      const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectIds.map((id) => {
        return { id, start: { date: newDate }, end: { date: newDate } };
      });

      await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);

      onChange && onChange(date!);
      await new Promise((resolve) => setTimeout(resolve, 100));
      onChange && onChange(dayjs(date).add(1, 'week').day(1).toDate());
      if (dayjs(date).format(DATE_FORMAT_4) < dayjs().format(DATE_FORMAT_4)) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      language === 'ko' ? toast.success('선택된 태스크박스를 다음주 월요일로 옮겼습니다.') : toast.success('Moved Selected taskboxes to the next Monday.');
      // toast.success('Moved selected taskboxes to the next Monday.');
    };

    /** 멀티 셀렉된 태스크박스 나중에로 옮기기 */
    const handleClickMultiSelectLater = async () => {
      //TODO
      // 완료된 하위태스크는 빼고 나중에로 옮기기
      onCloseMultiSelect();
      const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectIds.map((id) => {
        return { id, workSectionType: 'someday' };
      });

      await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);

      onChange && onChange(date!);
      if (dayjs(date).format(DATE_FORMAT_4) < dayjs().format(DATE_FORMAT_4)) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      language === 'ko' ? toast.success('선택된 태스크박스를 나중에로 옮겼습니다.') : toast.success('Moved selected taskboxes to later.');
      // toast.success('Moved selected taskboxes to later.');
      setTimeout(() => {
        fetchTasks();
      }, 100);
    };

    /** 멀티셀렉된 태스크박스 특정 일자로 미루는 로직 */
    const handleClickMultiSelectCalendar = async (value: Dayjs) => {
      onCloseMultiSelect();
      const newDate = dayjs(value).format(DATE_FORMAT_4);
      const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectIds.map((id) => {
        return { id, start: { date: newDate }, end: { date: newDate } };
      });

      await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);

      onChange && onChange(date!);
      await new Promise((resolve) => setTimeout(resolve, 100));
      onChange && onChange(dayjs(value).toDate());
      onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      language === 'ko'
        ? toast.success(`선택된 태스크박스를 ${dayjs(value).format(DATE_FORMAT_5)}로 옮겼습니다.`)
        : toast.success(`Moved selected taskboxes to ${dayjs(value).format(DATE_FORMAT_5)}.`);
      // toast.success(`Moved selected taskboxes to ${dayjs(value).format(DATE_FORMAT_5)}.`);
    };

    /** 멀티셀렉된 태스크박스 삭제 */
    const handleClickMultiSelectDelete = async () => {
      onCloseMultiSelect();

      const instances = entireBoardTaskboxes.filter((item) => multiSelectIds.includes(item.id!) && item.project);
      if (instances.length > 0) {
        for (const instance of instances) {
          if (instance.tasks && instance.tasks.length > 0 && instance.project) {
            await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(instance.project.id, { taskIds: instance.tasks.map((task) => task.id) as string[] });
          }
        }
        fetchProjects();
      }

      const params: RemoveTaskboxesV1TaskboxesDeleteParams = {
        id: multiSelectIds,
      };

      const options = {
        paramsSerializer: (params: any) => {
          if (Array.isArray(params.id)) {
            return params.id.map((id: any) => `id=${id}`).join('&');
          }
        },
      };

      await removeTaskboxesV1TaskboxesDelete(params, options);
      onChange && onChange(date!);
      language === 'ko' ? toast.success('선택된 태스크박스를 삭제했습니다.') : toast.success('Selected taskbox deleted.');
      // toast.success('Selected taskbox deleted.');
    };

    /** 멀티셀렉된 태스크박스 합치기 */
    const handleClickMultiSelectMerge = async () => {
      onCloseMultiSelect();
      const firstTaskbox = entireBoardTaskboxes.find((item) => item.id === multiSelectIds[0]);
      const isBefore = dayjs(firstTaskbox?.start?.date || firstTaskbox?.start?.datetime).isBefore(dayjs(), 'date');
      const success = isBefore
        ? await mergeWorkOrWorkBoxesV2V2WorksMergePost({ taskboxIds: multiSelectIds, done: true })
        : await mergeWorkOrWorkBoxesV2V2WorksMergePost({ taskboxIds: multiSelectIds });
      if (success) {
        onChange && onChange(date!);
        language === 'ko' ? toast.success('선택된 태스크박스를 합쳤습니다.') : toast.success('Task box grouped.');
        // toast.success('Task box grouped.');
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (isBefore) onChange(dayjs(firstTaskbox?.start?.date || firstTaskbox?.start?.datetime).toDate());
      }
    };

    /**멀티 셀렉된 태스크박스 드래그 시작 */
    const handleMultiSelectDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      onMultiSelectAnchorElNull();
      const el = document.querySelector('#grouping-taskbox-drag-image');
      e.dataTransfer.setDragImage(el!, 80, 3);
      setTaskViewDragContext({ type: 'multi-taskbox', view: 'taskboard', data: multiSelectIds, date: date! });
    };

    /**멀티 셀렉된 태스크박스 드래그 끝 */
    const handleMultiSelectDragEnd = () => {
      onCloseMultiSelect();
      setTaskViewDragContext(null);
    };

    const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType, detachSubtaskId?: string) => {
      switch (action) {
        case 'CREATE':
          {
            if (!category) return;
            const success = await createCategoryV1CategoryPost(category);
            if (success) {
              onFetchCategoryList && onFetchCategoryList();
            }
          }
          break;
        case 'UPDATE':
          {
            if (!category) return;
            const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
            if (success) {
              onFetchCategoryList && onFetchCategoryList();
            }
          }
          break;
        case 'DELETE':
          {
            if (!category) return;
            await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
            onFetchCategoryList && onFetchCategoryList();
          }
          break;
      }
    };

    const handleCreateListViewTaskbox = async () => {
      setIsCreateListViewTaskbox(true);

      const formattedDate = dayjs(date).format(DATE_FORMAT_4);
      setListViewTaskbox({
        id: uuidv4(),
        title: '',
        start: { date: formattedDate },
        end: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
        tasks: [],
        category: null,
        deadline: null,
        links: [],
        allDay: true,
        type: 'DAY_TASK',
        matchDate: [formattedDate],
      });

      setTimeout(() => {
        refListViewTaskbox.current?.focus();
      }, 100);
    };

    const handleChangeListViewTaskbox = (taskbox: OutTaskboxDetailResponse) => {
      setListViewTaskbox(taskbox);
    };

    const handleDeleteListViewTaskbox = async () => {
      setIsCreateListViewTaskbox(false);
      // onChange && onChange(date!);
    };

    const handleSaveListViewTaskbox = async (isClick?: boolean) => {
      userState.data.taskViewMode;
      const taskboxes = userState.data.taskViewMode === 'SPLIT' ? events.filter((event) => event.allDay) : events;
      const beforeId = taskboxes[taskboxes.length - 1] ? taskboxes[taskboxes.length - 1].id : null;
      const formattedDate = dayjs(date).format(DATE_FORMAT_4);
      try {
        onUpdateTaskboardFromListView && onUpdateTaskboardFromListView(formattedDate, [...events, listViewTaskbox]);
        setTimeout(() => {
          setListViewTaskbox({
            id: uuidv4(),
            title: '',
            start: { date: formattedDate },
            end: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
            tasks: [],
            category: null,
            deadline: null,
            links: [],
            allDay: true,
            type: 'DAY_TASK',
            matchDate: [formattedDate],
          });
        });

        await createTaskboxV1TaskboxesPost({
          ...listViewTaskbox,
          beforeId,
        } as CreateTaskbox);

        // if (isClick)
        //   toast.success(
        //     <div style={{ display: 'flex', alignItems: 'center' }}>
        //       다음에는
        //       <div
        //         style={{
        //           backgroundColor: COLORS.white,
        //           border: `1px solid ${COLORS.gray200}`,
        //           borderRadius: '2px',
        //           color: COLORS.gray800,
        //           fontSize: 13,
        //           margin: '0px 4px 0px 8px',
        //           padding: '2px 4px',
        //         }}
        //       >
        //         Shift
        //       </div>
        //       +
        //       <div
        //         style={{
        //           backgroundColor: COLORS.white,
        //           border: `1px solid ${COLORS.gray200}`,
        //           borderRadius: '2px',
        //           color: COLORS.gray800,
        //           fontSize: 13,
        //           margin: '0px 4px',
        //           padding: '2px 4px',
        //         }}
        //       >
        //         Enter
        //       </div>
        //       로 할 일을 추가해보세요.
        //     </div>,
        //   );

        if (isClick)
          toast.success(
            <div style={{ width: '350px', display: 'flex', alignItems: 'center' }}>
              {language === 'ko' ? (
                <>
                  다음에는
                  <div
                    style={{
                      backgroundColor: COLORS.white,
                      border: `1px solid ${COLORS.gray200}`,
                      borderRadius: '2px',
                      color: COLORS.gray800,
                      fontSize: 13,
                      margin: '0px 4px 0px 8px',
                      padding: '2px 4px',
                    }}
                  >
                    Shift
                  </div>
                  +
                  <div
                    style={{
                      backgroundColor: COLORS.white,
                      border: `1px solid ${COLORS.gray200}`,
                      borderRadius: '2px',
                      color: COLORS.gray800,
                      fontSize: 13,
                      margin: '0px 4px',
                      padding: '2px 4px',
                    }}
                  >
                    Enter
                  </div>
                  로 할 일을 추가해보세요.
                </>
              ) : (
                <>
                  <span>Next time, try adding a task with</span>
                  <div
                    style={{
                      backgroundColor: COLORS.white,
                      border: `1px solid ${COLORS.gray200}`,
                      borderRadius: '2px',
                      color: COLORS.gray800,
                      fontSize: 13,
                      margin: '0px 4px 0px 8px',
                      padding: '2px 4px',
                    }}
                  >
                    Shift
                  </div>
                  +
                  <div
                    style={{
                      backgroundColor: COLORS.white,
                      border: `1px solid ${COLORS.gray200}`,
                      borderRadius: '2px',
                      color: COLORS.gray800,
                      fontSize: 13,
                      margin: '0px 4px',
                      padding: '2px 4px',
                    }}
                  >
                    Enter
                  </div>
                </>
              )}
              {/* <span>Next time, try adding a task with</span>
              <div
                style={{
                  backgroundColor: COLORS.white,
                  border: `1px solid ${COLORS.gray200}`,
                  borderRadius: '2px',
                  color: COLORS.gray800,
                  fontSize: 13,
                  margin: '0px 4px 0px 8px',
                  padding: '2px 4px',
                }}
              >
                Shift
              </div>
              +
              <div
                style={{
                  backgroundColor: COLORS.white,
                  border: `1px solid ${COLORS.gray200}`,
                  borderRadius: '2px',
                  color: COLORS.gray800,
                  fontSize: 13,
                  margin: '0px 4px',
                  padding: '2px 4px',
                }}
              >
                Enter
              </div> */}
            </div>,
          );

        setTimeout(() => {
          refListViewTaskbox.current?.focus();
          onChange && onChange(date!);
        }, 100);
      } catch (e) {
        language === 'ko' ? toast.error('태스크박스를 저장하는 중 오류가 발생했습니다.') : toast.error('Error occurred while saving task box.');
        // toast.error('Error occurred while saving task box.');
      }
    };

    const [listViewTaskbox, setListViewTaskbox] = useState<OutTaskboxDetailResponse>({
      id: uuidv4(),
      title: '',
      start: { date: dayjs(date).format(DATE_FORMAT_4) },
      end: { date: dayjs(dayjs(date).format(DATE_FORMAT_4)).add(1, 'day').format(DATE_FORMAT_4) },
      tasks: [],
      category: null,
      deadline: null,
      links: [],
      allDay: true,
      type: 'DAY_TASK',
      matchDate: [dayjs(date).format(DATE_FORMAT_4)],
    });

    return (
      <div onDragEnter={handleTaskBoardDragEnter} onDragLeave={(e) => handleTaskBoardDragLeave(e)}>
        <DailyTaskViewWrapper hidden={dropStatus}>
          {isDragging && isHeaderInViewport && editing ? (
            <TaskBoardHeaderDropzone
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={() => setDragOver(false)}
              dragOver={isDragOver}
              hasEvent={!!events?.length}
            >
              {isDragOver ? (
                <span>
                  <b style={{ margin: '0px 4px 0px 8px' }}>{dayjs(date).format('dd요일')}</b> {dayjs(date).format('M월 DD일')}
                </span>
              ) : (
                <>
                  <Icons.DropZone stroke={isDragOver ? COLORS.sub4 : COLORS.brand1} />
                  <span style={{ marginLeft: '4px' }}>
                    {language === 'ko' ? '여기로 드래그해서 할 일로 만들어보세요' : 'Drag here to create a taskbox.'}
                    {/* Drag here to create a taskbox. */}
                  </span>
                </>
              )}
            </TaskBoardHeaderDropzone>
          ) : (
            <DateHeader
              events={events}
              date={date!}
              expanded={expanded}
              showFocusButton={isHeaderInViewport}
              focusedTaskBoard={focusedTaskBoard}
              incomplete={incomplete}
              editing={editing}
              onClickExpand={onClickExpand}
              onOpenIncomplete={handleOpenIncompleteDialog}
              onCloseIncomplete={handleCloseIncompleteDialog}
              onClickTomorrow={handleClickTomorrow}
              onClickNextWeek={handleClickNextWeek}
              onClickLater={handleClickLater}
              onClickCalendar={handleClickCalendar}
              onClickEditTaskBoard={onClickEditTaskBoard}
              onClickFocusIcon={handleClickFocusPlusIcon}
            />
          )}
          {events?.length || editing ? (
            <Wrapper
              ref={refTaskboard}
              onClick={(e) => {
                if (beautifulDrag) e.stopPropagation();
              }}
            >
              {isDragging && !isHeaderInViewport ? (
                <TaskBoardInputDropzone onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={() => setDragOver(false)} dragOver={isDragOver}>
                  <Icons.DropZone stroke={isDragOver ? COLORS.sub4 : COLORS.brand1} />
                  <span style={{ marginLeft: '4px' }}>
                    {language === 'ko' ? '여기로 드래그해서 할 일로 만들어보세요' : 'Drag here to create a taskbox.'}
                    {/* Drag here to create a taskbox. */}
                  </span>
                </TaskBoardInputDropzone>
              ) : (
                <CreateTaskboxInput
                  ref={refInput}
                  onCreate={onCreateTaskbox}
                  onChange={onChange}
                  date={date}
                  events={events}
                  incompleteTaskboxList={incompleteTaskboxList}
                  autoCompleteList={autoCompleteList}
                  categoryList={categoryList}
                  onFetchIncompleteTaskboxes={onFetchIncompleteTaskboxes}
                  onFetchAutoCompleteList={fetchAutoCompleteList}
                  onClickCategoryActions={handleClickCategoryActions}
                />
              )}
              {userState.data.taskViewMode === 'MERGED' && (
                <DragDropContext onDragStart={handleBeautifulDragStart} onDragEnd={handleBeautifulDragEnd}>
                  <div style={{ marginTop: '32px' }}>
                    {timelineItems.length === 0 ? (
                      <TimelineEmpty>
                        <TimelineEmptyContext>
                          <Icons.ListAdd stroke={COLORS.gray300} />
                          <Fonts.H5 style={{ color: COLORS.gray700, marginBottom: '4px', marginTop: '10px' }}>
                            {language === 'ko' ? '오늘 할 일은 무엇인가요?' : 'What tasks do you need to complete today?'}
                            {/* What tasks do you need to complete today? */}
                          </Fonts.H5>
                          <Fonts.Blockquote style={{ color: COLORS.gray400 }}>
                            {language === 'ko'
                              ? `할 일을 작성하거나 오른쪽 섹션의 '나중에'에서 Drag&Drop 해주세요.`
                              : `Write a task or Drag&Drop from the 'Later' section on the right.`}
                            {/* Write a task or Drag&Drop from the &apos;Later&apos; section on the right. */}
                          </Fonts.Blockquote>
                        </TimelineEmptyContext>
                      </TimelineEmpty>
                    ) : (
                      <>
                        {/* <TaskBoardToDoWrapper>
                          <Typography fontSize={20} fontWeight={'bold'} sx={{ marginLeft: 1 }}>
                            할 일 목록
                          </Typography>
                          <Typography fontSize={20} fontWeight={'bold'} color={COLORS.gray400} style={{ marginLeft: 4 }}>
                            ({events.filter((item) => item.allDay && item.type !== 'TERM_TASK').length})
                          </Typography>
                        </TaskBoardToDoWrapper> */}
                        <Droppable droppableId="all-taskbox" type="taksbox">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {timelineItems.map((event, index) => (
                                <div id="tutorial-drag-drop" key={event.id} ref={(elem) => (refTodoScroll ? (refTodoScroll.current[index] = elem) : {})}>
                                  <TimelineTaskbox
                                    key={event.id}
                                    ref={refTaskboard}
                                    date={date}
                                    index={index}
                                    expanded={expanded}
                                    event={event}
                                    events={timelineItems}
                                    selectedEvent={selectedEvent}
                                    selectedSubtaskId={selectedSubtaskId}
                                    multiSelectIds={multiSelectIds}
                                    multiSelected={multiSelectIds.includes(event.id!)}
                                    multiSelectAnchorEl={multiSelectAnchorEl}
                                    templateList={templateList}
                                    categoryList={categoryList}
                                    match={dayjs(currentDate).isSame(date)}
                                    multiSelectSubtaskIds={multiSelectSubtaskIds}
                                    multiSelectSubtaskAnchorEl={multiSelectSubtaskAnchorEl}
                                    onChange={onChange}
                                    onChangeWithoutFetchData={onChangeWithoutFetchData}
                                    onChangeSubtask={handleChangeSubtask}
                                    onChangeTaskboxImage={handleChangeTaskboxImage}
                                    onClickTaskbox={onClickTaskbox}
                                    onClickSubtask={onClickSubtask}
                                    onFetchIncompleteTaskboxes={onFetchIncompleteTaskboxes}
                                    onFetchTemplateList={onFetchTemplateList}
                                    onChangeMultiSelectIds={onChangeMultiSelectIds}
                                    onChangeMultiSelectIdsList={onChangeMultiSelectList}
                                    onCloseMultiSelect={onCloseMultiSelect}
                                    onClickMultiSelectActions={handleClickMultiSelectActions}
                                    onMultiSelectDragStart={handleMultiSelectDragStart}
                                    onMultiSelectDragEnd={handleMultiSelectDragEnd}
                                    onChangeTimer={onChangeTimer}
                                    onFetchCategoryList={onFetchCategoryList}
                                    onContextMenuTaskbox={onContextMenuTaskbox}
                                    onChangeMultiSelectSubtaskIds={onChangeMultiSelectSubtaskIds}
                                    onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
                                    onCloseMultiSelectSubtask={onCloseMultiSelectSubtask}
                                    onMultiSelectSubtaskAnchorElNull={onMultiSelectSubtaskAnchorElNull}
                                  />
                                </div>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </>
                    )}
                  </div>
                  {isCreateListViewTaskbox && (
                    <CreateListViewTaskbox
                      ref={refListViewTaskbox}
                      date={currentDate}
                      taskbox={listViewTaskbox}
                      onChange={handleChangeListViewTaskbox}
                      onCreate={handleSaveListViewTaskbox}
                      onDelete={handleDeleteListViewTaskbox}
                    />
                  )}
                  {!isCreateListViewTaskbox && (
                    <Tooltip
                      title={
                        <div style={{ padding: '4px 0px' }}>
                          <span style={{ marginRight: 8 }}>
                            {language === 'ko' ? '새로운 할 일 입력하기' : 'Enter new task'}
                            {/* Enter new task */}
                          </span>
                          <KeyboardButtonRect>Shift</KeyboardButtonRect>
                          <KeyboardCommandPlus>+</KeyboardCommandPlus>
                          <KeyboardButtonRect>/</KeyboardButtonRect>
                        </div>
                      }
                      disableInteractive
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCreateListViewTaskbox();
                        }}
                        sx={{
                          'height': '32px',
                          'display': 'flex',
                          'alignItems': 'center',
                          'borderRadius': '8px',
                          'color': COLORS.gray600,
                          'fontSize': '12px',
                          'fontWeight': 'bold',
                          'padding': '6px',
                          'textTransform': 'none',
                          ':hover': {
                            color: COLORS.brand1,
                            backgroundColor: COLORS.gray200,
                          },
                        }}
                      >
                        <Icons.Plus width={14} height={14} style={{ marginRight: 4 }} />
                        <span style={{ marginTop: 1 }}>
                          {language === 'ko' ? '새로운 할 일 입력하기' : 'Enter new task'}
                          {/* Enter new task */}
                        </span>
                      </Button>
                    </Tooltip>
                  )}
                </DragDropContext>
              )}
              {userState.data.taskViewMode === 'SPLIT' && (
                <DragDropContext onDragStart={handleBeautifulDragStart} onDragEnd={handleBeautifulDragEnd}>
                  <div style={{ marginTop: '32px' }}>
                    {timelineItems.filter((v) => v.allDay).length === 0 ? (
                      <TimelineEmpty>
                        <TimelineEmptyContext>
                          <Icons.ListAdd stroke={COLORS.gray300} />
                          <Fonts.H5 style={{ color: COLORS.gray700, marginBottom: '4px', marginTop: '10px' }}>
                            {language === 'ko' ? '오늘 할 일은 무엇인가요?' : 'What tasks do you need to complete today?'}
                            {/* What tasks do you need to complete today? */}
                          </Fonts.H5>
                          <Fonts.Blockquote style={{ color: COLORS.gray400 }}>
                            {language === 'ko'
                              ? `할 일을 작성하거나 오른쪽 섹션의 '나중에'에서 Drag&Drop 해주세요.`
                              : `Write a task or Drag&Drop from the 'Later' section on the right.`}
                            {/* Write a task or Drag&Drop from the &apos;Later&apos; section on the right. */}
                          </Fonts.Blockquote>
                        </TimelineEmptyContext>
                      </TimelineEmpty>
                    ) : (
                      <>
                        <Droppable droppableId="all-taskbox" type="taksbox">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {timelineItems
                                ?.filter((v) => v.allDay)
                                .map((event, index) => (
                                  <div id="tutorial-drag-drop" key={event.id} ref={(elem) => (refTodoScroll ? (refTodoScroll.current[index] = elem) : {})}>
                                    <TimelineTaskbox
                                      key={event.id}
                                      ref={refTaskboard}
                                      date={date}
                                      index={index}
                                      expanded={expanded}
                                      event={event}
                                      events={timelineItems}
                                      selectedEvent={selectedEvent}
                                      selectedSubtaskId={selectedSubtaskId}
                                      multiSelectIds={multiSelectIds}
                                      multiSelected={multiSelectIds.includes(event.id!)}
                                      multiSelectAnchorEl={multiSelectAnchorEl}
                                      templateList={templateList}
                                      categoryList={categoryList}
                                      match={dayjs(currentDate).isSame(date)}
                                      multiSelectSubtaskIds={multiSelectSubtaskIds}
                                      multiSelectSubtaskAnchorEl={multiSelectSubtaskAnchorEl}
                                      onChange={onChange}
                                      onChangeWithoutFetchData={onChangeWithoutFetchData}
                                      onChangeSubtask={handleChangeSubtask}
                                      onChangeTaskboxImage={handleChangeTaskboxImage}
                                      onClickTaskbox={onClickTaskbox}
                                      onClickSubtask={onClickSubtask}
                                      onFetchIncompleteTaskboxes={onFetchIncompleteTaskboxes}
                                      onFetchTemplateList={onFetchTemplateList}
                                      onChangeMultiSelectIds={onChangeMultiSelectIds}
                                      onChangeMultiSelectIdsList={onChangeMultiSelectList}
                                      onCloseMultiSelect={onCloseMultiSelect}
                                      onClickMultiSelectActions={handleClickMultiSelectActions}
                                      onMultiSelectDragStart={handleMultiSelectDragStart}
                                      onMultiSelectDragEnd={handleMultiSelectDragEnd}
                                      onChangeTimer={onChangeTimer}
                                      onFetchCategoryList={onFetchCategoryList}
                                      onContextMenuTaskbox={onContextMenuTaskbox}
                                      onChangeMultiSelectSubtaskIds={onChangeMultiSelectSubtaskIds}
                                      onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
                                      onCloseMultiSelectSubtask={onCloseMultiSelectSubtask}
                                      onMultiSelectSubtaskAnchorElNull={onMultiSelectSubtaskAnchorElNull}
                                    />
                                  </div>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </>
                    )}
                    {isCreateListViewTaskbox && (
                      <CreateListViewTaskbox
                        ref={refListViewTaskbox}
                        date={currentDate}
                        taskbox={listViewTaskbox}
                        onChange={handleChangeListViewTaskbox}
                        onCreate={handleSaveListViewTaskbox}
                        onDelete={handleDeleteListViewTaskbox}
                      />
                    )}
                    {!isCreateListViewTaskbox && (
                      <Tooltip
                        title={
                          <div style={{ padding: '4px 0px' }}>
                            <span style={{ marginRight: 8 }}>
                              {language === 'ko' ? '새로운 할 일 입력하기' : 'Enter new task'}
                              {/* Enter new task */}
                            </span>
                            <KeyboardButtonRect>Shift</KeyboardButtonRect>
                            <KeyboardCommandPlus>+</KeyboardCommandPlus>
                            <KeyboardButtonRect>/</KeyboardButtonRect>
                          </div>
                        }
                        disableInteractive
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCreateListViewTaskbox();
                          }}
                          sx={{
                            'height': '32px',
                            'display': 'flex',
                            'alignItems': 'center',
                            'borderRadius': '8px',
                            'color': COLORS.gray600,
                            'fontSize': '12px',
                            'fontWeight': 'bold',
                            'padding': '6px',
                            'textTransform': 'none',
                            ':hover': {
                              color: COLORS.brand1,
                              backgroundColor: COLORS.gray200,
                            },
                          }}
                        >
                          <Icons.Plus width={14} height={14} style={{ marginRight: 4 }} />
                          <span style={{ marginTop: 1 }}>
                            {language === 'ko' ? '새로운 할 일 입력하기' : 'Enter new task'}
                            {/* Enter new task */}
                          </span>
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                  {timelineItems.filter((v) => v.allDay === false).length > 0 && (
                    <div style={{ marginTop: 32 }}>
                      <TimelineHeaderTitle>
                        <Fonts.H3>
                          {language === 'ko' ? '타임블록한 태스크박스' : 'Time-blocked Taskbox'}
                          {/* Time-blocked Taskbox */}
                        </Fonts.H3>
                        <Typography fontSize={16} fontWeight={'bold'} color={COLORS.gray400} style={{ marginLeft: 4 }}>
                          ({timelineItems.filter((v) => v.allDay === false).length})
                        </Typography>
                      </TimelineHeaderTitle>
                      {timelineItems
                        ?.filter((v) => !v.allDay)
                        .map((event, index) => (
                          <div key={event.id} ref={(elem) => (refTimelineScroll ? (refTimelineScroll.current[index] = elem) : {})}>
                            <TimelineTaskbox
                              key={event.id}
                              ref={refTaskboard}
                              date={date}
                              event={event}
                              events={timelineItems}
                              expanded={expanded}
                              selectedEvent={selectedEvent}
                              selectedSubtaskId={selectedSubtaskId}
                              multiSelectIds={multiSelectIds}
                              multiSelected={multiSelectIds.includes(event.id!)}
                              multiSelectAnchorEl={multiSelectAnchorEl}
                              templateList={templateList}
                              categoryList={categoryList}
                              match={dayjs(currentDate).isSame(date)}
                              multiSelectSubtaskIds={multiSelectSubtaskIds}
                              multiSelectSubtaskAnchorEl={multiSelectSubtaskAnchorEl}
                              onChange={onChange}
                              onChangeWithoutFetchData={onChangeWithoutFetchData}
                              onChangeTaskboxImage={handleChangeTaskboxImage}
                              onChangeSubtask={handleChangeSubtask}
                              onClickTaskbox={onClickTaskbox}
                              onClickSubtask={onClickSubtask}
                              onFetchIncompleteTaskboxes={onFetchIncompleteTaskboxes}
                              onFetchTemplateList={onFetchTemplateList}
                              onChangeMultiSelectIds={onChangeMultiSelectIds}
                              onChangeMultiSelectIdsList={onChangeMultiSelectList}
                              onCloseMultiSelect={onCloseMultiSelect}
                              onClickMultiSelectActions={handleClickMultiSelectActions}
                              onMultiSelectDragStart={handleMultiSelectDragStart}
                              onMultiSelectDragEnd={handleMultiSelectDragEnd}
                              onChangeTimer={onChangeTimer}
                              onFetchCategoryList={onFetchCategoryList}
                              onContextMenuTaskbox={onContextMenuTaskbox}
                              onChangeMultiSelectSubtaskIds={onChangeMultiSelectSubtaskIds}
                              onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
                              onCloseMultiSelectSubtask={onCloseMultiSelectSubtask}
                              onMultiSelectSubtaskAnchorElNull={onMultiSelectSubtaskAnchorElNull}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </DragDropContext>
              )}
              <TaskboxDragImage id="taskbox-drag-image">
                <TaskboxDragImageLine
                  done={taskboxImage?.done}
                  isProject={!!taskboxImage?.project}
                  recurrence={taskboxImage?.isRecurrence || !!taskboxImage?.routine}
                  highlight={taskboxImage?.focus}
                />
                <TaskboxDragImageTitle done={taskboxImage?.done}>{taskboxImage?.title || (language === 'ko' ? '제목 없음' : 'Untitled')}</TaskboxDragImageTitle>
                {/* <TaskboxDragImageTitle done={taskboxImage?.done}>{taskboxImage?.title || 'Untitled'}</TaskboxDragImageTitle> */}
              </TaskboxDragImage>
              <TaskboxMultiSelectDragImage id="grouping-taskbox-drag-image">
                <TaskboxMultiSelectDragImageCount>{multiSelectIds.length}</TaskboxMultiSelectDragImageCount>
                <span style={{ fontSize: 13, marginLeft: 8 }}>
                  {language === 'ko' ? '선택됨' : 'Selected'}
                  {/* Selected */}
                </span>
              </TaskboxMultiSelectDragImage>
              <TaskboxSubtaskDragImage id="taskbox-subtask-drag-image">
                <TaskboxSubtaskDragImageCount style={{ backgroundColor: taskViewDragContext?.projectId ? COLORS.issue2 : COLORS.brand1 }}>
                  {multiSelectSubtaskIds.length > 0 ? multiSelectSubtaskIds[0].subtaskIds.length : 1}
                </TaskboxSubtaskDragImageCount>
                <span style={{ fontSize: 13, marginLeft: 8 }}>
                  {language === 'ko' ? '선택됨' : 'Selected'}
                  {/* Selected */}
                </span>
              </TaskboxSubtaskDragImage>
            </Wrapper>
          ) : (
            <NewTaskBoardWrapper>
              <Button disableElevation color="inherit" variant="text" sx={{ margin: 0, textTransform: 'none' }} onClick={() => onClickEditTaskBoard?.(date!)}>
                <Icons.Plus width={16} height={16} strokeWidth={2} stroke={COLORS.gray600} />
                <span style={{ color: COLORS.gray500, fontSize: 12, fontWeight: 'bold', marginLeft: 4, marginTop: 1 }}>
                  {language === 'ko' ? '업무 일지 만들기' : 'Create work log'}
                  {/* Create work log */}
                </span>
              </Button>
            </NewTaskBoardWrapper>
          )}
        </DailyTaskViewWrapper>
        <TaskBoardDropZone onDrop={handleDrop} hidden={!dropStatus}>
          {language === 'ko' ? dayjs(date).format('dd요일 M월 DD일') : dayjs(date).format('dddd, MMM DD')}
          {/* {dayjs(date).format('dddd, MMM DD')} */}
        </TaskBoardDropZone>
      </div>
    );
  },
);

const DailyTaskViewWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  margin-top: 12px;
  .fold-taskboard {
    opacity: 0;
  }
  &:hover {
    .fold-taskboard {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 24px;
`;

const TimelineHeaderTitle = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: ${COLORS.gray700};
`;

const TimelineEmpty = styled.div`
  width: 100%;
  height: 108px;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  margin-bottom: 8px;
`;

const TimelineEmptyContext = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
`;

const TaskboxDragImage = styled.div`
  width: 183px;
  height: 100px;
  cursor: pointer;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray300};
  border-radius: 8px;
  padding: 5px 6px 5px 8px;
  display: flex;
  position: absolute;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const TaskboxDragImageLine = styled.div<{ done?: boolean; isProject?: boolean; recurrence?: boolean; highlight?: boolean }>`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.brand1};
  border-radius: 8px;
  margin-right: 8px;

  ${(props) =>
    props.done &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.isProject &&
    css`
      background-color: ${COLORS.issue2};
    `}

  ${(props) =>
    props.recurrence &&
    css`
      background-color: ${COLORS.sub4};
    `}

    ${(props) =>
    props.highlight &&
    css`
      background: var(--Gradation, linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%));
    `}
`;

const TaskboxDragImageTitle = styled.div<{ done?: boolean }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #1f2023;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  ${(props) =>
    props.done &&
    css`
      opacity: 0.5;
      text-decoration: line-through;
    `}
`;

const TaskBoardHeaderDropzone = styled.div<{ dragOver?: boolean; hasEvent?: boolean }>`
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 24px;
  height: 48px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  z-index: 100;
  color: ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};

  ${(props) =>
    props.hasEvent &&
    css`
      height: 70px;
    `}
`;

const TaskBoardInputDropzone = styled.div<{ dragOver?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  font-size: 13px;
  color: ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
`;

const TaskBoardToDoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border: 1px solid ${COLORS.gray100};
`;

const NewTaskBoardWrapper = styled.div`
  width: 100%;
  min-height: 82px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 1px solid ${COLORS.gray100}; */
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: ${COLORS.gray100};
`;

const TaskBoardDropZone = styled.div<{ hidden: boolean }>`
  width: 100%;
  height: 130px;
  background-color: ${COLORS.sub5};
  border: 1px dashed ${COLORS.sub4};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.sub4};
  font-weight: 700;
  margin-top: 12px;
  ${(props) => props.hidden && `display: none;`}
`;

const TaskboxMultiSelectDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const TaskboxMultiSelectDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid ${COLORS.brand1};
  background: ${COLORS.brand1};
  border-radius: 50%;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

const TaskboxSubtaskDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const TaskboxSubtaskDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  background: ${COLORS.brand1};
  border-radius: 50%;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 4px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  color: ${COLORS.gray500};
  font-size: 12px;
  font-weight: 700;
  margin: 0px 4px;
`;

/** DateHeader Component */
export interface DateHeaderProps {
  events?: OutTaskboxDetailResponse[];
  date: Date;
  expanded?: boolean;
  showFocusButton?: boolean;
  focusedTaskBoard?: boolean;
  incomplete?: HTMLElement | null;
  editing?: boolean;
  onClickExpand?: (date: Date) => void;
  onClickFocusIcon?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onOpenIncomplete?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCloseIncomplete?: () => void;
  onClickTomorrow?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickNextWeek?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickLater?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickCalendar?: (value: Dayjs, taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickEditTaskBoard?: (date: Date) => void;
}

const DateHeader = ({
  events = [],
  date,
  expanded,
  showFocusButton,
  focusedTaskBoard,
  incomplete,
  editing,
  onClickExpand = () => {},
  onOpenIncomplete = () => {},
  onCloseIncomplete = () => {},
  onClickTomorrow = () => {},
  onClickFocusIcon = () => {},
  onClickNextWeek = () => {},
  onClickLater = () => {},
  onClickCalendar = () => {},
  onClickEditTaskBoard = () => {},
}: DateHeaderProps) => {
  const [filteredTaskbox, setFilteredTaskbox] = useState<OutTaskboxDetailResponse[]>(events!.filter((item) => item.type !== 'TERM_TASK'));
  const [doneTaskbox, setDoneTaskbox] = useState<OutTaskboxDetailResponse[]>(events!.filter((item) => item.done && item.type !== 'TERM_TASK'));
  const isToday = dayjs(date).isToday();
  const isBeforeDate = dayjs(date).isBefore(dayjs().startOf('day'));
  const isAfterDate = dayjs(date).isAfter(dayjs().startOf('day'));
  const [alarmTime] = useState<Date>(dayjs().startOf('day').set('hour', 17).toDate()); // [TODO] 나중에 알람 시간 변경 가능하도록 수정
  const [alarmTodaysIncomplete, setAlarmTodaysIncomplete] = useState<boolean>(false);
  const [language] = useAtom(languageAtom);

  const weekday = [
    { condition: dayjs(date).isToday(), value: language === 'ko' ? '오늘' : 'Today' },
    { condition: dayjs(date).isYesterday(), value: language === 'ko' ? '어제' : 'Yesterday' },
    { condition: dayjs(date).isTomorrow(), value: language === 'ko' ? '내일' : 'Tomorrow' },
  ].find((item) => item.condition)?.value;
  // const weekday = [
  //   { condition: dayjs(date).isToday(), value: 'Today' },
  //   { condition: dayjs(date).isYesterday(), value: 'Yesterday' },
  //   { condition: dayjs(date).isTomorrow(), value: 'Tomorrow' },
  // ].find((item) => item.condition)?.value;

  useEffect(() => {
    dayjs().isAfter(alarmTime) && isToday && (doneTaskbox.length / filteredTaskbox.length) * 100 <= 50
      ? setAlarmTodaysIncomplete(true)
      : setAlarmTodaysIncomplete(false);
  }, [doneTaskbox]);

  useEffect(() => {
    setFilteredTaskbox(events!.filter((item) => item.type !== 'TERM_TASK'));
    setDoneTaskbox(events!.filter((item) => item.done && item.type !== 'TERM_TASK'));
  }, [events]);

  const handleClickExpand = () => {
    onClickExpand?.(date);
  };

  const handleClickProgressText = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (alarmTodaysIncomplete) onOpenIncomplete(e);
    if (filteredTaskbox.length !== doneTaskbox.length && !isAfterDate) onOpenIncomplete(e);
  };

  return (
    <DateHeaderWrapper hasEvent={!!events?.length}>
      <DateHeaderTop>
        {showFocusButton && editing ? (
          <div onClick={(e) => onClickFocusIcon(e)}>
            <Tooltip
              title={
                language === 'ko' ? '새로운 할 일 입력하기' : 'Enter new task'
                // 'Enter new task'
              }
            >
              <IconButton
                sx={{
                  padding: '3px',
                  borderRadius: '6px',
                }}
              >
                <Icons.Plus width={16} height={16} strokeWidth={2} stroke={COLORS.gray400} style={{ cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div style={{ width: '22px' }} />
        )}
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            color: isToday ? COLORS.brand1 : COLORS.gray900,
            opacity: focusedTaskBoard ? '100%' : '60%',
          }}
        >
          {weekday && (
            <b
              style={{
                color: isToday ? COLORS.white : focusedTaskBoard ? COLORS.brand1 : COLORS.gray600,
                backgroundColor: isToday ? COLORS.brand1 : focusedTaskBoard ? COLORS.sub3 : COLORS.white,
                border: isToday ? 'none' : focusedTaskBoard ? `1px solid ${COLORS.brand1}` : `1px solid ${COLORS.gray300}`,
                padding: '2px 8px',
                fontSize: '13px',
                borderRadius: '40px',
              }}
            >
              {weekday}
            </b>
          )}
          <b style={{ margin: '0px 4px 0px 8px' }}>{language === 'ko' ? dayjs(date).format('dd요일') : dayjs(date).format('dddd')}</b>{' '}
          {language === 'ko' ? dayjs(date).format('M월 DD일') : dayjs(date).format('MMM D')}
          {/* <b style={{ margin: '0px 4px 0px 8px' }}>{dayjs(date).format('dddd')}</b> {dayjs(date).format('MMM D')} */}
        </span>
        {events?.length === 0 ? (
          editing ? (
            <Tooltip
              className="fold-taskboard"
              title={
                language === 'ko' ? '업무 일지 접기' : 'Collapse work journal'
                // 'Collapse work journal'
              }
              disableInteractive
            >
              <IconButton sx={{ padding: '3px' }} onClick={() => onClickEditTaskBoard?.(date!)}>
                <Icons.Close width={16} height={16} stroke={COLORS.gray600} />
              </IconButton>
            </Tooltip>
          ) : (
            <div style={{ width: '22px' }} />
          )
        ) : (
          <div id={'tutorial-fold-subtask'}>
            <Tooltip
              title={
                language === 'ko' ? (expanded ? '하위 업무 접기' : '하위 업무 펼치기') : expanded ? 'Collapse subtasks' : 'Expand subtasks'
                // expanded ? 'Collapse subtasks' : 'Expand subtasks'
              }
              disableInteractive
            >
              <IconButton
                sx={{
                  'padding': '3px',
                  'borderRadius': '6px',
                  '&:hover': { background: !expanded ? COLORS.sub2 : COLORS.sub3 },
                  'background': !expanded ? COLORS.sub2 : 'white',
                }}
                onClick={handleClickExpand}
              >
                {expanded ? <SubTaskCollapse /> : <SubTaskExpand fill={'white'} />}
              </IconButton>
            </Tooltip>
          </div>
        )}
      </DateHeaderTop>

      {filteredTaskbox.length > 0 && (
        <DateHeaderProgress isFocused={focusedTaskBoard} allTaskboxDone={filteredTaskbox.length === doneTaskbox.length} isBeforeDate={isBeforeDate}>
          <LinearProgress
            variant="determinate"
            value={(doneTaskbox.length / filteredTaskbox.length) * 100}
            sx={{ width: '100%', marginRight: '34px', marginTop: '2px', height: '12px', borderRadius: '5px' }}
          />
          <DateHeaderProgressText
            onClick={(e) => {
              handleClickProgressText(e);
            }}
            allTaskboxDone={filteredTaskbox.length === doneTaskbox.length}
            isToday={isToday}
            isBeforeDate={isBeforeDate}
            isAfterDate={isAfterDate}
            alarmTodaysIncomplete={alarmTodaysIncomplete}
          >
            {`(${doneTaskbox.length}/${filteredTaskbox.length})`}
            {alarmTodaysIncomplete && <DateHeaderProgressTextDot isToday={isToday} />}
            {filteredTaskbox.length !== doneTaskbox.length && isBeforeDate && <DateHeaderProgressTextDot />}
          </DateHeaderProgressText>
        </DateHeaderProgress>
      )}
      {incomplete && (
        <TaskboxIncompleteDetail
          events={events}
          incomplete={incomplete}
          date={date}
          onCloseIncomplete={onCloseIncomplete}
          onClickTomorrow={onClickTomorrow}
          onClickNextWeek={onClickNextWeek}
          onClickLater={onClickLater}
          onClickCalendar={onClickCalendar}
        />
      )}
    </DateHeaderWrapper>
  );
};

const DateHeaderWrapper = styled.div<{ hasEvent?: boolean }>`
  top: 0;
  position: sticky;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px;
  height: 48px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${COLORS.gray100};
  border-bottom: 1px solid ${COLORS.gray200};

  ${(props) =>
    props.hasEvent &&
    css`
      height: 70px;
    `}
`;

const DateHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`;

const DateHeaderProgress = styled.div<{ isFocused?: boolean; allTaskboxDone?: boolean; isBeforeDate?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    !props.isFocused &&
    css`
      opacity: 60%;
    `}

  .MuiLinearProgress-colorPrimary {
    border: 1px solid ${COLORS.white};
    background-color: ${COLORS.gray200};
    ${(props) =>
      props.isBeforeDate &&
      css`
        background-color: #fcdfe6;
      `}
  }

  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${COLORS.brand1};
    ${(props) =>
      props.allTaskboxDone &&
      css`
        background-color: ${COLORS.positive1};
      `}
  }
`;

type DateHeaderProgressTextProps = {
  allTaskboxDone?: boolean;
  isToday?: boolean;
  isBeforeDate?: boolean;
  isAfterDate?: boolean;
  alarmTodaysIncomplete?: boolean;
};

const DateHeaderProgressText = styled.div<DateHeaderProgressTextProps>`
  font-size: 13px;
  font-weight: 700;
  color: ${COLORS.brand1};
  display: flex;
  position: relative;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  ${(props) =>
    props.alarmTodaysIncomplete &&
    css`
      cursor: pointer;
      color: ${COLORS.brand1};
      &:hover {
        background-color: ${COLORS.gray200};
      }
    `}

  ${(props) =>
    !props.allTaskboxDone &&
    props.isBeforeDate &&
    css`
      cursor: pointer;
      color: ${COLORS.negative1};
      &:hover {
        background-color: ${COLORS.gray200};
      }
    `}

  ${(props) =>
    props.allTaskboxDone &&
    css`
      color: ${COLORS.positive1};
    `}
`;

const DateHeaderProgressTextDot = styled.div<{ isToday?: boolean }>`
  width: 5px;
  height: 5px;
  background-color: ${COLORS.negative1};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: -5px;

  ${(props) =>
    props.isToday &&
    css`
      background-color: ${COLORS.brand1};
    `};
`;
/** DateHeader Component */

/** FrequentWork */
export interface FrequentWorkProps {
  date?: Date;
  events?: OutTaskboxDetailResponse[];
  incompleteTaskboxList?: OutAutoComplete[];
  autoCompleteList?: AutoCompleteWorkList[];
  categoryList?: OutCategory[];
  onCreate?: (taskbox: CreateTaskbox) => void;
  onChange?: (date: Date) => void;
  onFetchIncompleteTaskboxes?: (title?: string) => void;
  onFetchAutoCompleteList?: (title?: string) => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType, detachSubtaskId?: string) => void;
}

export interface FrequentWorkImperativeHandle {
  focus?: () => void;
}

export interface IncompleteWorkList extends OutTaskboxDetailResponse {
  date?: string;
}

export interface AutoCompleteWorkList extends OutAutoComplete {
  date?: string;
}

const CreateTaskboxInput = forwardRef<FrequentWorkImperativeHandle, FrequentWorkProps>(
  (
    {
      date,
      events,
      incompleteTaskboxList = [],
      autoCompleteList = [],
      categoryList = [],
      onCreate = () => {},
      onChange = () => {},
      onFetchIncompleteTaskboxes = () => {},
      onFetchAutoCompleteList = () => {},
      onClickCategoryActions = () => {},
    }: FrequentWorkProps,
    ref: any,
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const refFrequentInput = useRef<HTMLInputElement>(null);
    const refCloseInput = useRef<HTMLInputElement>(null);
    const [incompleteWork, setIncompleteWork] = useState<IncompleteWorkList | null>(null);
    const [foldIncompleteWork, setFoldIncompleteWork] = useState(false);
    const [isAutoComplete, setIsAutoComplete] = useState(false);
    const [incompleteWorkList, setIncompleteWorkList] = useState<IncompleteWorkList[]>([]);
    const [openIncompleteDetailDialog, setOpenIncompleteDetailDialog] = useState(false);
    const [openIncompleteDeleteDialog, setOpenIncompleteDeleteDialog] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [multiSelect, setMultiSelect] = useState(false);
    const [multiSelectList, setMultiSelectList] = useState<string[]>([]);
    const [, fetchTasks] = useAtom(tasksAtom);
    const refScroll = useRef<HTMLDivElement[] | null[]>([]);
    const refScrollContainer = useRef<HTMLDivElement | null>(null);
    const [isHoverCheckbox, setIsHoverCheckbox] = useState(false);
    const [hoverCheckboxId, setHoverCheckboxId] = useState<string | null>(null);
    const [autoCompleteTitle, setAutoCompleteTitle] = useState<string>('');
    const [language] = useAtom(languageAtom);

    useEffect(() => {
      const incompleteWork = incompleteTaskboxList!
        .filter((item) => item.done === false && dayjs(item.start?.date || item.start?.datetime).isBefore(dayjs(date).subtract(1, 'day')))
        .map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_5) }))
        .sort((a, b) => (a.date > b.date ? -1 : 1));
      setIncompleteWorkList(incompleteWork);
    }, [incompleteTaskboxList]);

    useImperativeHandle(ref, () => ({ focus: () => refFrequentInput?.current?.focus() }));

    useClickOutside(refCloseInput, () => {
      if (!refFrequentInput.current) return;
      if (refFrequentInput.current.value) return;
      if (openIncompleteDetailDialog) return;
      if (openIncompleteDeleteDialog) return;
      setIsFocus(false);
      setFoldIncompleteWork(false);
      if (quickCategory) setQuickCategory(null);
      if (quickDeadline) setQuickDeadline(null);
      if (quickProject) setQuickProject(null);
    });

    useEffect(() => {
      const el = refScroll.current[focusedIndex];
      if (el) {
        if (isAutoComplete) refScrollContainer.current?.scrollTo({ top: Math.max(el.offsetTop - 309, 0), behavior: 'smooth' });
        else refScrollContainer.current?.scrollTo({ top: Math.max(el.offsetTop - 345, 0), behavior: 'smooth' });
      }
    }, [focusedIndex]);

    useUpdateEffect(() => {
      if (isAutoComplete && autoCompleteList.length > 0) {
        setFoldIncompleteWork(true);
      }
    }, [isAutoComplete]);

    useLayoutEffect(() => {
      handleRefresh();
    }, [foldIncompleteWork]);

    const handleRefresh = () => {
      if (foldIncompleteWork) setFocusedIndex(-1);
      if (multiSelectList.length > 0) setMultiSelectList([]);
      if (multiSelect) setMultiSelect(false);
      // setIncompleteWork(null);
    };

    const handleAutoCompleteTitle = (title: string) => {
      setAutoCompleteTitle(title);
    };

    const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!refFrequentInput.current) return;

      const value = refFrequentInput.current?.value;

      if (e.key === 'Escape') {
        if (value) {
          refFrequentInput.current.blur();
          return;
        }
        e.preventDefault();
        refFrequentInput.current.blur();
        setIsFocus(false);
        setFoldIncompleteWork(false);
        if (quickCategory) setQuickCategory(null);
        if (quickDeadline) setQuickDeadline(null);
        if (quickProject) setQuickProject(null);
      }

      if (e.key === 'Enter') {
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }

        if (focusedIndex >= 0) {
          const update = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWorkList[focusedIndex].id!, {
            start: { date: dayjs(date).format(DATE_FORMAT_4) },
            end: { date: dayjs(date).format(DATE_FORMAT_4) },
          });

          if (update) {
            onFetchIncompleteTaskboxes();
            setFoldIncompleteWork(false);
            setFocusedIndex(-1);
            onChange(date!);
            await new Promise((resolve) => setTimeout(resolve, 300));
            onChange && onChange(dayjs(incompleteWorkList[focusedIndex].start?.date || incompleteWorkList[focusedIndex].start?.datetime).toDate());
          }
        } else {
          refFrequentInput.current.value = '';
          const newTaskbox: CreateTaskbox = {
            title: value,
            categoryId: quickCategory ? [quickCategory.id] : [],
            deadline: quickDeadline ? quickDeadline : null,
            projectId: quickProject ? [quickProject.id] : [],
          };
          onCreate?.(newTaskbox);
          if (quickCategory) setQuickCategory(null);
          if (quickDeadline) setQuickDeadline(null);
          if (quickProject) setQuickProject(null);
          setIsAutoComplete(false);
          setFoldIncompleteWork(false);
        }
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (e.nativeEvent.isComposing) return;
        if (quickCategory || quickDeadline || quickProject) return;
        setFoldIncompleteWork(true);
        if (foldIncompleteWork) {
          if (isAutoComplete) setFocusedIndex((prevIndex) => (prevIndex < autoCompleteList.length - 1 ? prevIndex + 1 : prevIndex));
          else setFocusedIndex((prevIndex) => (prevIndex < incompleteWorkList.length - 1 ? prevIndex + 1 : prevIndex));
        } else {
          setFocusedIndex(-1);
        }
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (e.nativeEvent.isComposing) return;
        if (!foldIncompleteWork) return;
        if (focusedIndex === 0 || focusedIndex === -1) setFoldIncompleteWork(false);
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
      }

      if (e.key === '#') {
        if (quickProject) {
          language === 'ko'
            ? toast.error('프로젝트를 지정한 태스크는 카테고리를 지정할 수 없습니다.')
            : toast.error(`The task with a specified project can't have a category.`);
          // toast.error(`The task with a specified project can't have a category.`);
          return;
        }

        setTimeout(() => {
          setCategoryAnchorEl(refFrequentInput.current);
        }, 100);
      }

      if (e.key === '$') {
        setTimeout(() => {
          setDeadlineAnchorEl(refFrequentInput.current);
        }, 100);
      }

      if (e.key === '>') {
        if (!projectFeature.data.create) return;
        setTimeout(() => {
          setProjectListAnchorEl(refFrequentInput.current);
        }, 100);
      }
    };

    const handleFocus = () => {
      setIsFocus(true);
      refFrequentInput.current?.focus();
    };

    const handleFilterTitle = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value.trim();
      if (!inputValue) {
        setFoldIncompleteWork(false);
        setIsAutoComplete(false);
      } else {
        handleAutoCompleteTitle(inputValue);
        onFetchAutoCompleteList(inputValue);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setIsAutoComplete(true);
      }
    }, 500);

    const handlePullIncompleteWork = async (taskbox: IncompleteWorkList) => {
      const update = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        title: taskbox.title,
        start: { date: dayjs(date).format(DATE_FORMAT_4) },
        end: { date: dayjs(date).format(DATE_FORMAT_4) },
        tasks: taskbox.tasks as UpdateTaskForTaskBox[],
        beforeId: null,
      });

      if (update) {
        setOpenIncompleteDetailDialog(false);
        setFoldIncompleteWork(false);
        onFetchIncompleteTaskboxes();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
        onChange(date!);
        await new Promise((resolve) => setTimeout(resolve, 300));
        onChange && onChange(dayjs(taskbox.start?.date || taskbox.start?.datetime).toDate());
      }
    };

    const handleClickIncompleteDetailDialog = (incompleteWork: IncompleteWorkList) => {
      setIncompleteWork(incompleteWork);
      setOpenIncompleteDetailDialog(true);
    };

    const handleClickIncompleteLater = async (incompleteWork: IncompleteWorkList) => {
      const result = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, { workSectionType: 'someday' });
      if (result) {
        language === 'ko' ? toast.success('미완료 업무를 나중에로 옮겼습니다.') : toast.success('Moved incompleted tasks to Later.');
        // toast.success('Moved incompleted tasks to Later.');
        setOpenIncompleteDetailDialog(false);
        onChange && onChange(date!);
        onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
        await new Promise((resolve) => setTimeout(resolve, 300));
        onChange && onChange(dayjs(incompleteWork.start?.date || incompleteWork.start?.datetime).toDate());
        fetchTasks();
      }
    };

    const handleClickIncompleteDeleteDialog = (incompleteWork: IncompleteWorkList) => {
      setIncompleteWork(incompleteWork);
      setOpenIncompleteDeleteDialog(true);
    };

    const handleCloseIncompleteDeleteDialog = () => {
      setOpenIncompleteDeleteDialog(false);
    };

    const handleDeleteIncompleteWork = async (incompleteWork: IncompleteWorkList) => {
      const result = await removeTaskboxV1TaskboxesTaskboxIdDelete(incompleteWork.id!);
      if (result) {
        language === 'ko' ? toast.success('미완료 업무를 삭제하였습니다.') : toast.success('Incompleted tasks deleted.');
        // toast.success('Incompleted tasks deleted.');
        setOpenIncompleteDetailDialog(false);
        onChange && onChange(dayjs(incompleteWork.start?.date || incompleteWork.start?.datetime).toDate());
        onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handlePutIncompleteWork = async (incompleteWork: IncompleteWorkList) => {
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, {
        ...incompleteWork,
        categoryId: incompleteWork.category ? [incompleteWork.category.id] : [],
        tasks: incompleteWork.tasks!.map((task) => ({ ...task, categoryId: task.category ? [task.category?.id] : [] })) as UpdateTaskForTaskBox[],
      });

      if (incompleteWork.done) {
        await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, {
          done: true,
        });
      }
      // const success2 = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, { tasks: incompleteWork.tasks });
      if (success) {
        setOpenIncompleteDetailDialog(false);
        onChange && onChange(dayjs(incompleteWork.start?.date || incompleteWork.start?.datetime).toDate());
        onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handleChangeIncompleteWork = (taskbox: IncompleteWorkList) => {
      setIncompleteWork({ ...taskbox });
    };

    const handleChangeIncompleteTaskBlocks = (
      tasks: { id: string; content?: string; done?: boolean; category?: OutCategory; deadline?: string | null }[] = [],
    ) => {
      const newTasks = tasks.map((task) => ({ ...task }));
      setIncompleteWork({ ...incompleteWork!, done: newTasks.filter((v) => v.done).length === newTasks.length ? true : false, tasks: newTasks });
    };

    const handleClickMultiSelect = () => {
      setMultiSelect(!multiSelect);
      if (multiSelectList.length > 0) {
        setMultiSelectList([]);
      }
    };

    const handleMultiSelectList = (id: string) => {
      if (multiSelectList.includes(id)) {
        setMultiSelectList(multiSelectList.filter((item) => item !== id));
      } else {
        setMultiSelectList([...multiSelectList, id]);
      }
    };
    const handleCancelMultiSelectList = () => {
      setMultiSelectList([]);
    };
    const handleClickSelectAllMultiSelect = () => {
      setMultiSelectList(incompleteWorkList.map((item) => item.id!).slice(0, 10));
    };

    const handleDeleteMultiSelectList = async () => {
      setFoldIncompleteWork(false);
      const list: OutTaskboxDetail[] = [];
      incompleteWorkList.map((item) => {
        if (multiSelectList.includes(item.id!)) {
          list.push(item);
        }
      });
      if (list.length === 0) return;

      const success = await Promise.all(list.map(async (item) => await removeTaskboxV1TaskboxesTaskboxIdDelete(item.id!)));

      if (success) {
        list.map((item) => onChange(dayjs(item.start?.date || item.start?.datetime).toDate()));
        onChange(date!);
        onFetchIncompleteTaskboxes();
        language === 'ko' ? toast.success('선택된 미완료 업무를 삭제했습니다.') : toast.success('Selected incompleted tasks deleted.');
        // toast.success('Selected incompleted tasks deleted.');
      } else {
        language === 'ko' ? toast.error('선택된 미완료 업무를 삭제하는데 실패했습니다.') : toast.error('Failed to delete selected incompleted tasks.');
        // toast.error('Failed to delete selected incompleted tasks.');
      }
    };

    const handleLaterMultiSelectList = async () => {
      setFoldIncompleteWork(false);
      const list: OutTaskboxDetail[] = [];
      incompleteWorkList.map((item) => {
        if (multiSelectList.includes(item.id!)) {
          list.push(item);
        }
      });
      if (list.length === 0) return;

      const success = await Promise.all(
        list.map(
          async (item) =>
            await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, {
              workSectionType: 'someday',
            }),
        ),
      );

      if (success) {
        list.map((item) => onChange(dayjs(item.start?.date || item.start?.datetime).toDate()));
        onChange(date!);
        onFetchIncompleteTaskboxes();
        fetchTasks();
        language === 'ko' ? toast.success('선택된 미완료 업무를 나중에로 보냈습니다.') : toast.success('Moved selected incompleted tasks to Later.');
        // toast.success('Moved selected incompleted tasks to Later.');
      } else {
        language === 'ko'
          ? toast.error('선택된 미완료 업무를 나중에로 보내는데 실패했습니다.')
          : toast.error('Failed to move selected incompleted tasks to Later.');
        // toast.error('Failed to move selected incompleted tasks to Later.');
      }
    };
    const handlePullMultiSelectList = async () => {
      setFoldIncompleteWork(false);
      const list: OutTaskboxDetail[] = [];
      incompleteWorkList.map((item) => {
        if (multiSelectList.includes(item.id!)) {
          list.push(item);
        }
      });
      if (list.length === 0) return;

      const success = await Promise.all(
        list.map(
          async (item) =>
            await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, {
              start: { date: dayjs(date).format(DATE_FORMAT_4) },
              end: { date: dayjs(date).format(DATE_FORMAT_4) },
            }),
        ),
      );

      if (success) {
        list.map((item) => onChange(dayjs(item.start?.date || item.start?.datetime).toDate()));
        onChange(date!);
        onFetchIncompleteTaskboxes();
        language === 'ko' ? toast.success('선택된 미완료 업무를 가져왔습니다.') : toast.success('Retrieved selected incompleted tasks.');
        // toast.success('Retrieved selected incompleted tasks.');
      } else {
        language === 'ko' ? toast.error('선택된 미완료 업무를 가져오는데 실패했습니다.') : toast.error('Failed to retrieve selected incompleted tasks.');
        // toast.error('Failed to retrieve selected incompleted tasks.');
      }
    };

    const handleIncompleteWorkCheckbox = async (incompleteWork: IncompleteWorkList) => {
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork!.id!, {
        done: true,
      });
      if (success) {
        if (openIncompleteDetailDialog) setOpenIncompleteDetailDialog(false);
        onChange && onChange(dayjs(incompleteWork!.start?.date || incompleteWork!.start?.datetime).toDate());
        onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handleIncompleteWorkDetailCheckbox = async (incompleteWork: IncompleteWorkList) => {
      if (incompleteWork.done) {
        const newTasks = incompleteWork!.tasks!.map((task) => ({ ...task, done: false }));
        setIncompleteWork({ ...incompleteWork, done: false, tasks: newTasks });
      } else {
        const newTasks = incompleteWork!.tasks!.map((task) => ({ ...task, done: true }));
        setIncompleteWork({ ...incompleteWork, done: true, tasks: newTasks });
      }
    };

    const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
    const [quickCategory, setQuickCategory] = useState<OutCategory | null>(null);
    const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
    const [quickDeadline, setQuickDeadline] = useState<string | null>(null);
    const [projectListAnchorEl, setProjectListAnchorEl] = useState<HTMLElement | null>(null);
    const [quickProject, setQuickProject] = useState<OutProject | null>(null);
    const [projectFeature] = useAtom(projectFeatureAtom);

    const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
      if (action === 'SELECT') {
        setQuickCategory(category);
        setCategoryAnchorEl(null);

        // 마지막 텍스트가 #이면 #을 지워준다.
        const value = refFrequentInput.current?.value;
        if (value && value.includes('#')) {
          refFrequentInput.current.value = value.replace(/#/g, '');
        }
      } else {
        onClickCategoryActions && onClickCategoryActions(category!, action);
      }
    };

    const handleClickDeadline = (date: Dayjs | null) => {
      setQuickDeadline(date ? date.format(DATE_FORMAT_4) : null);

      setTimeout(() => {
        setDeadlineAnchorEl(null);
      }, 100);

      // 마지막 텍스트가 $이면 $을 지워준다.
      const value = refFrequentInput.current?.value;
      if (value && value.includes('$')) {
        refFrequentInput.current.value = value.replace(/\$/g, '');
      }
    };

    const handleClickProjectList = (project: OutProject | null) => {
      setQuickProject(project);
      setProjectListAnchorEl(null);
      if (quickCategory) {
        setQuickCategory(null);
        language === 'ko'
          ? toast.error('프로젝트를 지정한 태스크는 카테고리를 지정할 수 없습니다.')
          : toast.error(`The task with a specified project can't have a category.`);
        // toast.error(`The task with a specified project can't have a category.`);
      }

      // 마지막 텍스트가 >이면 >을 지워준다.
      const value = refFrequentInput.current?.value;
      if (value && value.includes('>')) {
        refFrequentInput.current.value = value.replace(/>/g, '');
      }
    };

    return (
      <CreateTaskboxContainer ref={refCloseInput}>
        <FrequentWorkContainer foldIncompleteWork={foldIncompleteWork}>
          <TaskboxInputWrapper>
            {isFocus ? (
              <IssueCheckBox circleColor={quickProject ? '#CD6CFC' : undefined} pathColor={quickProject ? '#F3DAFE' : undefined} />
            ) : (
              <div onClick={handleFocus} style={{ cursor: 'pointer' }}>
                <Icons.Plus width={18} height={18} stroke={COLORS.gray400} />
              </div>
            )}
            <TextField
              id={dayjs().isSame(dayjs(date), 'date') ? 'taskboard-input' : ''}
              inputRef={refFrequentInput}
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder={
                isFocus
                  ? dayjs(date).isBefore(dayjs(), 'date')
                    ? language === 'ko'
                      ? '한 일을 입력해주세요'
                      : 'Please enter completed task'
                    : language === 'ko'
                    ? '할 일을 입력해주세요'
                    : 'Please enter a task.'
                  : dayjs(date).isBefore(dayjs(), 'date')
                  ? language === 'ko'
                    ? '한 일 입력하기'
                    : 'Enter completed task'
                  : dayjs(date).isSame(dayjs(), 'date')
                  ? language === 'ko'
                    ? '로 오늘 할 일 입력하기'
                    : 'Enter today’s task'
                  : language === 'ko'
                  ? '새로운 할 일 입력하기'
                  : 'Enter new task'
              }
              // placeholder={
              //   isFocus
              //     ? dayjs(date).isBefore(dayjs(), 'date')
              //       ? 'Please enter completed task'
              //       : 'Please enter a task.'
              //     : dayjs(date).isBefore(dayjs(), 'date')
              //     ? 'Enter completed task'
              //     : dayjs(date).isSame(dayjs(), 'date')
              //     ? 'Enter today’s task'
              //     : 'Enter new task'
              // }
              onKeyDown={handleKeydownInput}
              onFocus={handleFocus}
              onChange={handleFilterTitle}
              InputProps={{
                disableUnderline: true,
                startAdornment: dayjs().isSame(dayjs(date), 'date') && !isFocus && (
                  <InputAdornment position="start" sx={{ width: '16px' }}>
                    <TaskboardInputPlaceholder>
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: COLORS.gray100,
                          textAlign: 'center',
                          borderRadius: '2px',
                          marginBottom: '1px',
                        }}
                      >
                        /
                      </div>
                    </TaskboardInputPlaceholder>
                  </InputAdornment>
                ),
                style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 },
              }}
              style={{ margin: '1px 8px 0px 8px', flex: 1 }}
            />
            {quickCategory && (
              <CategoryShowingWrapper
                textColor={getCategoryTextColor(quickCategory.color)}
                bgColor={getCategoryBgColor(quickCategory.color)}
                style={{ marginRight: quickDeadline ? '4px' : '0px' }}
              >
                {`# ${quickCategory.name}`}
              </CategoryShowingWrapper>
            )}
            {quickDeadline && (
              <DeadlineShowingWrapper date={quickDeadline} style={{ marginRight: quickProject ? '4px' : '0px' }}>
                <Icons.Flag
                  fill={dayjs(quickDeadline).isToday() ? COLORS.brand1 : dayjs(quickDeadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                />
                <span style={{ marginLeft: '2px' }}>
                  {dayjs(quickDeadline).isToday()
                    ? language === 'ko'
                      ? '오늘'
                      : 'Today'
                    : dayjs(quickDeadline).isYesterday()
                    ? language === 'ko'
                      ? '어제'
                      : 'Yesterday'
                    : dayjs(quickDeadline).isTomorrow()
                    ? language === 'ko'
                      ? '내일'
                      : 'Tomorrow'
                    : language === 'ko'
                    ? dayjs(quickDeadline).format('M월 D일 (dd)')
                    : dayjs(quickDeadline).format('dddd, MMM D')}
                </span>
                {/* <span style={{ marginLeft: '2px' }}>
                  {dayjs(quickDeadline).isToday()
                    ? 'Today'
                    : dayjs(quickDeadline).isYesterday()
                    ? 'Yesterday'
                    : dayjs(quickDeadline).isTomorrow()
                    ? 'Tomorrow'
                    : dayjs(quickDeadline).format('dddd, MMM D')}
                </span> */}
              </DeadlineShowingWrapper>
            )}
            {quickProject && (
              <ProjectShowingWrapper>
                <Icons.Issue style={{ marginRight: 6 }} />
                <div
                  style={{
                    maxWidth: '130px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {quickProject.title}
                </div>
              </ProjectShowingWrapper>
            )}
            {isFocus && !quickCategory && !quickDeadline && !quickProject && (
              <CategoryDeadlineInfoPlaceholder>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    title={
                      language === 'ko' ? '#으로 카테고리 지정' : `Set the category using '#'`
                      // `Set the category using '#'`
                    }
                    disableInteractive
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '16px',
                        height: '16px',
                        borderRadius: '2px',
                        backgroundColor: COLORS.gray100,
                        marginRight: '4px',
                      }}
                    >
                      #
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      language === 'ko' ? '$으로 기한 지정' : `Set the deadline using '$'`
                      // `Set the deadline using '$'`
                    }
                    disableInteractive
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '16px',
                        height: '16px',
                        borderRadius: '2px',
                        backgroundColor: COLORS.gray100,
                        marginRight: '4px',
                      }}
                    >
                      $
                    </div>
                  </Tooltip>
                  {projectFeature.data.create && (
                    <Tooltip
                      title={
                        language === 'ko' ? '>으로 프로젝트에 연결' : `Connect to the project using '>'`
                        // `Connect to the project using '>'`
                      }
                      disableInteractive
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '16px',
                          height: '16px',
                          borderRadius: '2px',
                          backgroundColor: COLORS.gray100,
                          marginRight: '4px',
                        }}
                      >
                        {'>'}
                      </div>
                    </Tooltip>
                  )}
                </div>
              </CategoryDeadlineInfoPlaceholder>
            )}
            {!quickCategory && !quickDeadline && !quickProject && incompleteWorkList.length > 0 && (
              <Tooltip
                title={
                  language === 'ko' ? (
                    <IncompleteWorkKeyDownWrapper>
                      <IncompleteWorkKeyDownIcon style={{ marginRight: '4px', transform: foldIncompleteWork ? 'rotate(90deg)' : 'rotate(270deg)' }}>
                        <Icons.ArrowBack stroke={COLORS.gray500} />
                      </IncompleteWorkKeyDownIcon>
                      <span>{foldIncompleteWork ? '으로 완료되지 않은 업무 접기' : '으로 완료되지 않은 업무 보기'}</span>
                    </IncompleteWorkKeyDownWrapper>
                  ) : (
                    <IncompleteWorkKeyDownWrapper>
                      <span>{foldIncompleteWork ? 'Collapse incompleted tasks with' : 'Expand incompleted tasks with'}</span>
                      <IncompleteWorkKeyDownIcon style={{ marginLeft: '4px', transform: foldIncompleteWork ? 'rotate(90deg)' : 'rotate(270deg)' }}>
                        <Icons.ArrowBack stroke={COLORS.gray500} />
                      </IncompleteWorkKeyDownIcon>
                    </IncompleteWorkKeyDownWrapper>
                  )
                }
                disableInteractive
              >
                <IncompleteWorkFoldWrpapper>
                  {foldIncompleteWork ? (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => {
                        setFoldIncompleteWork(false);
                      }}
                    >
                      <Icons.ArrowUpSmall width={20} height={20} fill={COLORS.brand1} />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          onFetchIncompleteTaskboxes();
                          setFoldIncompleteWork(true);
                        }}
                      >
                        <Icons.ArrowDownSmall width={20} height={20} fill={COLORS.brand1} />
                      </IconButton>
                      {incompleteWorkList.length > 0 && <IncompleteWorkRedDot />}
                    </>
                  )}
                </IncompleteWorkFoldWrpapper>
              </Tooltip>
            )}
          </TaskboxInputWrapper>
          {foldIncompleteWork && (
            <>
              {((isAutoComplete && autoCompleteList.length > 0) || (!isAutoComplete && incompleteWorkList.length > 0)) && <Divider style={{ width: '94%' }} />}
              {autoCompleteList.length > 0 && isAutoComplete && (
                <IncompleteWorkContainer>
                  <IncompleteWorkListContentContainer id="incomplete-work-list" ref={refScrollContainer} style={{ maxHeight: '288px' }}>
                    {autoCompleteList.map((item, index) => (
                      <IncompleteWorkListContent
                        key={item.id}
                        focus={focusedIndex === index}
                        data-incomplete-index={index}
                        onClick={() => {
                          handlePullIncompleteWork(item);
                        }}
                        onMouseLeave={() => {
                          setIsHoverCheckbox(false);
                          setHoverCheckboxId(null);
                        }}
                        ref={(elem) => (refScroll ? (refScroll.current[index] = elem) : {})}
                      >
                        <IncompleteWorkListContentInfo>
                          {multiSelect && (
                            <>
                              <Checkbox
                                checked={multiSelectList.includes(item.id!)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMultiSelectList(item.id!);
                                }}
                                sx={{ padding: '0px' }}
                              />
                              <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                            </>
                          )}
                          {isHoverCheckbox && hoverCheckboxId === item.id ? (
                            <div
                              onMouseLeave={() => {
                                setIsHoverCheckbox(false);
                                setHoverCheckboxId(null);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleIncompleteWorkCheckbox(item);
                              }}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.5" cx="8" cy="8" r="8" fill="#2D6ADF" />
                                <circle cx="8" cy="8" r="7.5" stroke="#0039A7" />
                                <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          ) : item.tasks!.filter((v) => v.done).length! > 0 ? (
                            <div
                              onMouseEnter={() => {
                                setIsHoverCheckbox(true);
                                setHoverCheckboxId(item.id!);
                              }}
                            >
                              <Icons.InprogressCheck width={16} height={16} />
                            </div>
                          ) : (
                            item.tasks!.filter((v) => v.done).length! === 0 && (
                              <div
                                onMouseEnter={() => {
                                  setIsHoverCheckbox(true);
                                  setHoverCheckboxId(item.id!);
                                }}
                              >
                                <IssueCheckBox height={16} width={16} />
                              </div>
                            )
                          )}
                          <IncompleteWorkListContentInfoTitle>{item.title}</IncompleteWorkListContentInfoTitle>
                          {item.category && (
                            <CategoryShowingWrapper textColor={getCategoryTextColor(item.category.color)} bgColor={getCategoryBgColor(item.category.color)}>
                              {item.category.name}
                            </CategoryShowingWrapper>
                          )}
                          {item.deadline && (
                            <DeadlineShowingWrapper date={item.deadline}>
                              <Icons.Flag
                                fill={
                                  dayjs(item.deadline).isToday() ? COLORS.brand1 : dayjs(item.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                                }
                              />
                              <span style={{ marginLeft: '2px' }}>
                                {dayjs(item.deadline).isToday()
                                  ? language === 'ko'
                                    ? '오늘'
                                    : 'Today'
                                  : dayjs(item.deadline).isYesterday()
                                  ? language === 'ko'
                                    ? '어제'
                                    : 'Yesterday'
                                  : dayjs(item.deadline).isTomorrow()
                                  ? language === 'ko'
                                    ? '내일'
                                    : 'Tomorrow'
                                  : language === 'ko'
                                  ? dayjs(item.deadline).format('M월 D일 (dd)')
                                  : dayjs(item.deadline).format('dddd, MMM D')}
                              </span>
                              {/* <span style={{ marginLeft: '2px' }}>
                                {dayjs(item.deadline).isToday()
                                  ? 'Today'
                                  : dayjs(item.deadline).isYesterday()
                                  ? 'Yesterday'
                                  : dayjs(item.deadline).isTomorrow()
                                  ? 'Tomorrow'
                                  : dayjs(item.deadline).format('dddd, MMM D')}
                              </span> */}
                            </DeadlineShowingWrapper>
                          )}
                          {item.memo && <Icons.TaskboxMemo stroke={COLORS.gray300} />}
                          {item.links && item.links.length > 0 && <Icons.Link2 stroke={COLORS.gray300} />}
                          {item.tasks!.length > 0 && (
                            <IncompleteWorkListContentInfoSubtask>
                              <Icons.Subtask stroke={COLORS.gray300} />
                              <div style={{ margin: '2px 0px 0px 2px' }}>{item.tasks?.length}</div>
                            </IncompleteWorkListContentInfoSubtask>
                          )}
                        </IncompleteWorkListContentInfo>
                        <IncompleteWorkListDueDate className="incomplete-duedate">
                          {dayjs(item.start?.date || item.start?.datetime).isSame(dayjs(date).subtract(1, 'day'), 'day')
                            ? language === 'ko'
                              ? '어제'
                              : 'Yesterday'
                            : item.date}
                          {/* {dayjs(item.start?.date || item.start?.datetime).isSame(dayjs(date).subtract(1, 'day'), 'day') ? 'Yesterday' : item.date} */}
                        </IncompleteWorkListDueDate>
                        <IncompleteWorkListOptions
                          className="incomplete-options"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {focusedIndex === index && (
                            <AddIncompleteWorkWithEnter style={{ display: 'flex', alignItems: 'center' }}>
                              {language === 'ko' ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginRight: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div>로 추가하기</div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              ) : (
                                <>
                                  <div>Add with</div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginLeft: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              )}
                              {/* <div>Add with</div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '2px 4px',
                                  backgroundColor: COLORS.white,
                                  border: `0.5px solid ${COLORS.gray400}`,
                                  borderRadius: '2px',
                                  marginLeft: '4px',
                                  fontWeight: 400,
                                }}
                              >
                                Enter
                              </div>
                              <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} /> */}
                            </AddIncompleteWorkWithEnter>
                          )}
                          <IncompleteDetailButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDetailDialog(item);
                            }}
                          >
                            {language === 'ko' ? '상세보기' : 'Details'}
                            {/* Details */}
                          </IncompleteDetailButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteLater(item);
                            }}
                            sx={{
                              'margin': '0px 8px',
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDeleteDialog(item);
                            }}
                            sx={{
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Delete fill={COLORS.gray400} />
                          </IconButton>
                        </IncompleteWorkListOptions>
                      </IncompleteWorkListContent>
                    ))}
                  </IncompleteWorkListContentContainer>
                </IncompleteWorkContainer>
              )}
              {incompleteWorkList.length > 0 && !isAutoComplete && (
                <IncompleteWorkContainer>
                  {multiSelect ? (
                    <IncompleteWorkListHeaderContainer>
                      <IncompleteWorkListHeaderTitle>
                        <IconButton
                          onClick={handleClickMultiSelect}
                          sx={{
                            padding: '0px',
                            marginRight: '4px',
                          }}
                        >
                          <Icons.ArrowLeftSmall fill={COLORS.gray400} />
                        </IconButton>
                        <IncompleteWorkHeaderTitleText>
                          {language === 'ko' ? '완료되지 않은 업무' : 'Incomplete taskboxes'}
                          {/* Incomplete taskboxes */}
                        </IncompleteWorkHeaderTitleText>
                      </IncompleteWorkListHeaderTitle>
                      <IncompleteWorkMultiSelectionOptions>
                        {multiSelectList.length > 0 ? (
                          <SelectMultiSelection>
                            <span>
                              {language === 'ko' ? `${multiSelectList.length}개 선택` : `${multiSelectList.length} selected`}
                              {/* {multiSelectList.length}개 선택 */}
                            </span>
                            <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 4px' }} />
                            <CancelMultiSelect onClick={handleCancelMultiSelectList}>
                              {language === 'ko' ? '취소' : 'Cancel'}
                              {/* Cancel */}
                            </CancelMultiSelect>
                          </SelectMultiSelection>
                        ) : (
                          <SelectAllMultiSelection onClick={handleClickSelectAllMultiSelect}>
                            {language === 'ko' ? '전체 선택' : 'Select all'}
                            {/* Select all */}
                          </SelectAllMultiSelection>
                        )}
                        <MultiSelectionIcon>
                          <IconButton disabled={multiSelectList.length === 0} onClick={handleLaterMultiSelectList} sx={{ padding: '4px', borderRadius: '8px' }}>
                            <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            disabled={multiSelectList.length === 0}
                            onClick={() => setOpenIncompleteDeleteDialog(true)}
                            sx={{ padding: '4px', borderRadius: '8px' }}
                          >
                            <Icons.Delete width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                        </MultiSelectionIcon>
                        <Button
                          disabled={multiSelectList.length === 0}
                          variant="contained"
                          disableElevation
                          disableRipple
                          onClick={handlePullMultiSelectList}
                          sx={{ height: '28px', padding: '4px 12px', borderRadius: '8px', fontSize: '12px', fontWeight: 700 }}
                        >
                          {language === 'ko' ? '추가하기' : 'Add'}
                          {/* Add */}
                        </Button>
                      </IncompleteWorkMultiSelectionOptions>
                    </IncompleteWorkListHeaderContainer>
                  ) : (
                    <IncompleteWorkListHeaderContainer>
                      <IncompleteWorkListHeaderTitle>
                        <IncompleteWorkHeaderTitleText>
                          {language === 'ko' ? '완료되지 않은 업무' : 'Incomplete taskboxes'}
                          {/* Incomplete taskboxes */}
                        </IncompleteWorkHeaderTitleText>
                      </IncompleteWorkListHeaderTitle>
                      <IncompleteWorkMultiSelect onClick={handleClickMultiSelect}>
                        {language === 'ko' ? '다중 선택' : 'Multi select'}
                        {/* Multi select */}
                      </IncompleteWorkMultiSelect>
                    </IncompleteWorkListHeaderContainer>
                  )}
                  <IncompleteWorkListContentContainer id="incomplete-work-list" ref={refScrollContainer} style={{ maxHeight: '288px' }}>
                    {incompleteWorkList.map((item, index) => (
                      <IncompleteWorkListContent
                        key={item.id}
                        focus={focusedIndex === index}
                        data-incomplete-index={index}
                        onClick={() => {
                          handlePullIncompleteWork(item);
                        }}
                        onMouseLeave={() => {
                          setIsHoverCheckbox(false);
                          setHoverCheckboxId(null);
                        }}
                        ref={(elem) => (refScroll ? (refScroll.current[index] = elem) : {})}
                      >
                        <IncompleteWorkListContentInfo>
                          {multiSelect && (
                            <>
                              <Checkbox
                                checked={multiSelectList.includes(item.id!)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMultiSelectList(item.id!);
                                }}
                                sx={{ padding: '0px' }}
                              />
                              <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                            </>
                          )}
                          {isHoverCheckbox && hoverCheckboxId === item.id ? (
                            <div
                              onMouseLeave={() => {
                                setIsHoverCheckbox(false);
                                setHoverCheckboxId(null);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleIncompleteWorkCheckbox(item);
                              }}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.5" cx="8" cy="8" r="8" fill="#2D6ADF" />
                                <circle cx="8" cy="8" r="7.5" stroke="#0039A7" />
                                <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          ) : item.tasks!.filter((v) => v.done).length! > 0 ? (
                            <div
                              onMouseEnter={() => {
                                setIsHoverCheckbox(true);
                                setHoverCheckboxId(item.id!);
                              }}
                            >
                              <Icons.InprogressCheck width={16} height={16} />
                            </div>
                          ) : (
                            item.tasks!.filter((v) => v.done).length! === 0 && (
                              <div
                                onMouseEnter={() => {
                                  setIsHoverCheckbox(true);
                                  setHoverCheckboxId(item.id!);
                                }}
                              >
                                <IssueCheckBox height={16} width={16} />
                              </div>
                            )
                          )}
                          <IncompleteWorkListContentInfoTitle>{item.title}</IncompleteWorkListContentInfoTitle>
                          {item.category && (
                            <CategoryShowingWrapper textColor={getCategoryTextColor(item.category.color)} bgColor={getCategoryBgColor(item.category.color)}>
                              {item.category.name}
                            </CategoryShowingWrapper>
                          )}
                          {item.deadline && (
                            <DeadlineShowingWrapper date={item.deadline}>
                              <Icons.Flag
                                fill={
                                  dayjs(item.deadline).isToday() ? COLORS.brand1 : dayjs(item.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                                }
                              />
                              <span style={{ marginLeft: '2px' }}>
                                {dayjs(item.deadline).isToday()
                                  ? language === 'ko'
                                    ? '오늘'
                                    : 'Today'
                                  : dayjs(item.deadline).isYesterday()
                                  ? language === 'ko'
                                    ? '어제'
                                    : 'Yesterday'
                                  : dayjs(item.deadline).isTomorrow()
                                  ? language === 'ko'
                                    ? '내일'
                                    : 'Tomorrow'
                                  : language === 'ko'
                                  ? dayjs(item.deadline).format('M월 D일 (dd)')
                                  : dayjs(item.deadline).format('dddd, MMM D')}
                              </span>
                              {/* <span style={{ marginLeft: '2px' }}>
                                {dayjs(item.deadline).isToday()
                                  ? 'Today'
                                  : dayjs(item.deadline).isYesterday()
                                  ? 'Yesterday'
                                  : dayjs(item.deadline).isTomorrow()
                                  ? 'Tomorrow'
                                  : dayjs(item.deadline).format('dddd, MMM D')}
                              </span> */}
                            </DeadlineShowingWrapper>
                          )}
                          {item.memo && <Icons.TaskboxMemo stroke={COLORS.gray300} />}
                          {item.links && item.links.length > 0 && <Icons.Link2 stroke={COLORS.gray300} />}
                          {item.tasks!.length > 0 && (
                            <IncompleteWorkListContentInfoSubtask>
                              <Icons.Subtask stroke={COLORS.gray300} />
                              <div style={{ margin: '2px 0px 0px 2px' }}>{item.tasks?.length}</div>
                            </IncompleteWorkListContentInfoSubtask>
                          )}
                        </IncompleteWorkListContentInfo>
                        <IncompleteWorkListDueDate className="incomplete-duedate">
                          {dayjs(item.start?.date || item.start?.datetime).isSame(dayjs(date).subtract(1, 'day'), 'day')
                            ? language === 'ko'
                              ? '어제'
                              : 'Yesterday'
                            : item.date}
                          {/* {dayjs(item.start?.date || item.start?.datetime).isSame(dayjs(date).subtract(1, 'day'), 'day') ? 'Yesterday' : item.date} */}
                        </IncompleteWorkListDueDate>
                        <IncompleteWorkListOptions
                          className="incomplete-options"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {focusedIndex === index && (
                            <AddIncompleteWorkWithEnter style={{ display: 'flex', alignItems: 'center' }}>
                              {language === 'ko' ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginRight: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div>로 추가하기</div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              ) : (
                                <>
                                  <div>Add with</div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginLeft: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              )}
                              {/* <div>Add with</div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '2px 4px',
                                  backgroundColor: COLORS.white,
                                  border: `0.5px solid ${COLORS.gray400}`,
                                  borderRadius: '2px',
                                  marginLeft: '4px',
                                  fontWeight: 400,
                                }}
                              >
                                Enter
                              </div>
                              <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} /> */}
                            </AddIncompleteWorkWithEnter>
                          )}
                          <IncompleteDetailButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDetailDialog(item);
                            }}
                          >
                            {language === 'ko' ? '상세보기' : 'Details'}
                            {/* Details */}
                          </IncompleteDetailButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteLater(item);
                            }}
                            sx={{
                              'margin': '0px 8px',
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDeleteDialog(item);
                            }}
                            sx={{
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Delete fill={COLORS.gray400} />
                          </IconButton>
                        </IncompleteWorkListOptions>
                      </IncompleteWorkListContent>
                    ))}
                  </IncompleteWorkListContentContainer>
                </IncompleteWorkContainer>
              )}
            </>
          )}
        </FrequentWorkContainer>
        {/* 태스크박스 카테고리 */}
        {categoryAnchorEl && (
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setCategoryAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
          </Popover>
        )}
        {/* 태스크박스 기한 */}
        {deadlineAnchorEl && (
          <Popover
            open={Boolean(deadlineAnchorEl)}
            anchorEl={deadlineAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setDeadlineAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleClickDeadline} />
          </Popover>
        )}
        {/* 태스크박스 프로젝트 */}
        {projectListAnchorEl && (
          <Popover
            open={Boolean(projectListAnchorEl)}
            anchorEl={projectListAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setProjectListAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <ProjectListPopover onClick={handleClickProjectList} />
          </Popover>
        )}
        <Dialog
          open={openIncompleteDetailDialog}
          onClose={() => {
            handlePutIncompleteWork(incompleteWork!);
          }}
        >
          <IncompleteWorkDetail
            incompleteWork={incompleteWork!}
            categoryList={categoryList}
            onClickAddTaskbox={handlePullIncompleteWork}
            onClickLater={handleClickIncompleteLater}
            onClickDelete={handleDeleteIncompleteWork}
            onClickCheckbox={handleIncompleteWorkDetailCheckbox}
            onChangeTaskBlocks={handleChangeIncompleteTaskBlocks}
            onChange={handleChangeIncompleteWork}
            onClickCategory={onClickCategoryActions}
          />
        </Dialog>
        <Dialog
          open={openIncompleteDeleteDialog}
          onClose={handleCloseIncompleteDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}`, textAlign: 'center' }}>
            {language === 'ko' ? '미완료 업무를 삭제하시겠어요?' : 'Delete incomplete tasks?'}
            {/* Delete incomplete tasks? */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
              {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
              {/* Deletes all related data. */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseIncompleteDeleteDialog}>{language === 'ko' ? '취소' : 'Cancel'}</Button>
            <Button
              onClick={() => {
                multiSelectList.length > 0 ? handleDeleteMultiSelectList() : handleDeleteIncompleteWork(incompleteWork!);
                handleCloseIncompleteDeleteDialog();
              }}
            >
              {language === 'ko' ? '삭제' : 'Delete'}
              {/* Delete */}
            </Button>
          </DialogActions>
        </Dialog>
      </CreateTaskboxContainer>
    );
  },
);

const CreateTaskboxContainer = styled.div`
  height: 50px;
  position: relative;
`;

const TaskboxInputWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
`;

const TaskboardInputPlaceholder = styled.div`
  color: ${COLORS.gray500};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const CategoryDeadlineInfoPlaceholder = styled.div`
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  margin-right: 8px;
`;

const IncompleteWorkKeyDownWrapper = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.white};
  display: flex;
  align-items: center;
`;

const IncompleteWorkKeyDownIcon = styled.div`
  width: 16px;
  height: 16px;
  padding: 4px;
  border-radius: 2px;
  background-color: ${COLORS.gray100};
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IncompleteWorkFoldWrpapper = styled.div`
  position: relative;
`;

const IncompleteWorkRedDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${COLORS.negative1};
  position: absolute;
  top: 0px;
  left: 16px;
`;

const FrequentWorkContainer = styled.div<{ foldIncompleteWork: boolean }>`
  position: absolute;
  width: 100%;
  background-color: ${COLORS.white};
  z-index: 5;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.foldIncompleteWork &&
    css`
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
      z-index: 101;
    `}
`;

const IncompleteWorkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  .MuiButton-root {
    &:hover {
      border: none;
      border-top: 1px solid ${COLORS.gray200};
    }
  }
`;

const IncompleteWorkListHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 12px;
`;

const IncompleteWorkListHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const IncompleteWorkHeaderTitleText = styled.div`
  font-size: 12px;
  color: ${COLORS.gray500};
`;

const IncompleteWorkMultiSelect = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.gray400};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const IncompleteWorkMultiSelectionOptions = styled.div`
  display: flex;
  align-items: center;
`;

const SelectMultiSelection = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${COLORS.gray400};
`;

const CancelMultiSelect = styled.div`
  cursor: pointer;
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const SelectAllMultiSelection = styled.div`
  font-size: 12px;
  color: ${COLORS.gray400};
  cursor: pointer;
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const MultiSelectionIcon = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  margin: 0px 8px;
  padding: 2px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
`;

const IncompleteWorkHeaderPullWorkText = styled.div`
  font-size: 12px;
  margin-left: 4px;
`;

const IncompleteWorkListContentContainer = styled.div`
  max-height: 180px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const IncompleteWorkListContent = styled.div<{ focus?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  height: 36px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
    .incomplete-options {
      opacity: 1;
    }
    .incomplete-duedate {
      opacity: 0;
    }
  }

  ${(props) =>
    props.focus &&
    css`
      background-color: ${COLORS.gray100};
      .incomplete-options {
        opacity: 1;
      }
      .incomplete-duedate {
        opacity: 0;
      }
    `}
`;

const IncompleteWorkListContentInfo = styled.div`
  display: flex;
  align-items: center;
`;

const IncompleteWorkListContentInfoTitle = styled.div`
  color: ${COLORS.gray900};
  font-size: 16px;
  font-weight: 600;
  margin: 0px 8px;
  max-width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IncompleteWorkListContentInfoSubtask = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${COLORS.gray300};
`;

const IncompleteWorkListDueDate = styled.div`
  color: ${COLORS.negative1};
  font-size: 10px;
`;

const IncompleteWorkListOptions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  opacity: 0;
`;

const AddIncompleteWorkWithEnter = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray400};
  font-size: 10px;
  font-weight: 700;
`;

const IncompleteDetailButton = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray400};
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const IncompletePropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 24px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

const ProjectShowingWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray700};
  font-size: 13px;
  font-weight: 700;
`;
/** */

/** IncompleteWorkDetail */
interface IncompleteWorkDetailProps {
  incompleteWork?: IncompleteWorkList;
  categoryList?: OutCategory[];
  onClickAddTaskbox?: (incompleteWork: IncompleteWorkList) => void;
  onClickLater?: (incompleteWork: IncompleteWorkList) => void;
  onClickDelete?: (incompleteWork: IncompleteWorkList) => void;
  onClickCheckbox?: (incompleteWork: IncompleteWorkList) => void;
  onChangeTaskBlocks?: (tasks: { id: string; content?: string; category?: OutCategory; deadline?: string | null }[]) => void;
  onChange?: (incompleteWork: IncompleteWorkList) => void;
  onClickCategory?: (category: OutCategory | null, action: CategoryActionType) => void;
}

const IncompleteWorkDetail = ({
  incompleteWork,
  categoryList = [],
  onClickAddTaskbox = () => {},
  onClickLater = () => {},
  onClickDelete = () => {},
  onClickCheckbox = () => {},
  onChangeTaskBlocks = () => {},
  onChange = () => {},
  onClickCategory = () => {},
}: IncompleteWorkDetailProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const [incompleteWorkDetail, setIncompleteWorkDetail] = useState<HTMLElement | null>(null);
  const [openIncompleteDeleteDialog, setDeleteIncomplete] = useState(false);
  const [language] = useAtom(languageAtom);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;

    if (e.key === 'Escape') {
      if (value) {
        refInput.current.blur();
        return;
      }
      e.preventDefault();
      refInput.current.blur();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refTaskBlockList?.current?.add();
    }
  };

  const handleBlurInput = () => {
    if (!refInput.current) return;
    onChange({ ...incompleteWork!, title: refInput.current.value });
  };

  const handleCloseIncompleteWorkPopover = () => {
    setIncompleteWorkDetail(null);
  };

  const handleIncompleteWorkTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ ...incompleteWork!, title: e.target.value });
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      onChange({ ...incompleteWork!, category: category });
      setCategoryAnchorEl(null);
    } else {
      if (action === 'DELETE') {
        const updatedTasks = incompleteWork?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: undefined };
          }
          return task;
        });
        onChange({ ...incompleteWork!, category: null, tasks: updatedTasks });
        setCategoryAnchorEl(null);
      } else if (action === 'UPDATE') {
        const updatedTasks = incompleteWork?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: category! };
          }
          return task;
        });
        onChange({
          ...incompleteWork!,
          category: incompleteWork?.category && incompleteWork!.category!.id === category!.id ? category : incompleteWork?.category,
          tasks: updatedTasks,
        });
      }
      onClickCategory(category, action);
    }
  };

  const handleClickDeadline = (date: Dayjs | null) => {
    onChange({ ...incompleteWork!, deadline: date ? date.format('YYYY-MM-DD') : null });
    setDeadlineAnchorEl(null);
  };

  return (
    <IncompleteWorkDialogWrapper>
      <IncompletePropertyWrapper>
        {incompleteWork && incompleteWork.category ? (
          <Tooltip title={language === 'ko' ? '카테고리 설정하기' : 'Set category'} disableInteractive>
            <CategoryShowingWrapper
              textColor={getCategoryTextColor(incompleteWork.category!.color)}
              bgColor={getCategoryBgColor(incompleteWork.category!.color)}
              onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
            >
              {`# ${incompleteWork.category.name}`}
              <IconButton
                className="category-detach-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickCategory(null, 'SELECT');
                }}
                sx={{
                  'width': '12px',
                  'height': '12px',
                  'borderRadius': '4px',
                  'marginLeft': '4px',
                  'marginTop': '1px',
                  'padding': '0px',
                  ':hover': {
                    backgroundColor: hexToRGBA(getCategoryTextColor(incompleteWork.category!.color)!, 0.3),
                  },
                }}
                style={categoryAnchorEl ? { display: 'flex' } : {}}
              >
                <Icons.Close width={8} height={8} stroke={getCategoryTextColor(incompleteWork.category!.color)} />
              </IconButton>
            </CategoryShowingWrapper>
          </Tooltip>
        ) : (
          <Tooltip title={language === 'ko' ? '카테고리 설정하기' : 'Set category'} disableInteractive>
            <IconButton
              onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
              sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
            >
              <Icons.Hashtag />
            </IconButton>
          </Tooltip>
        )}
        {incompleteWork && incompleteWork.deadline ? (
          <Tooltip
            title={
              language === 'ko' ? '기한 설정하기' : 'Set deadline'
              // 'Set deadline'
            }
            disableInteractive
          >
            <DeadlineShowingWrapper date={incompleteWork.deadline} onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}>
              <Icons.Flag
                fill={
                  dayjs(incompleteWork.deadline).isToday()
                    ? COLORS.brand1
                    : dayjs(incompleteWork.deadline).isBefore(dayjs())
                    ? COLORS.negative1
                    : COLORS.gray600
                }
              />
              <span style={{ marginLeft: '2px' }}>
                {dayjs(incompleteWork.deadline).isToday()
                  ? language === 'ko'
                    ? '오늘'
                    : 'Today'
                  : dayjs(incompleteWork.deadline).isYesterday()
                  ? language === 'ko'
                    ? '어제'
                    : 'Yesterday'
                  : dayjs(incompleteWork.deadline).isTomorrow()
                  ? language === 'ko'
                    ? '내일'
                    : 'Tomorrow'
                  : language === 'ko'
                  ? dayjs(incompleteWork.deadline).format('M월 D일 (dd)')
                  : dayjs(incompleteWork.deadline).format('dddd, MM D')}
                {/* {dayjs(incompleteWork.deadline).isToday()
                  ? 'Today'
                  : dayjs(incompleteWork.deadline).isYesterday()
                  ? 'Yesterday'
                  : dayjs(incompleteWork.deadline).isTomorrow()
                  ? 'Tomorrow'
                  : dayjs(incompleteWork.deadline).format('dddd, MM D')} */}
              </span>
              <IconButton
                className="deadline-detach-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickDeadline(null);
                }}
                sx={{
                  'width': '12px',
                  'height': '12px',
                  'borderRadius': '4px',
                  'marginLeft': '4px',
                  'marginTop': '1px',
                  'padding': '0px',
                  ':hover': {
                    backgroundColor: hexToRGBA(
                      dayjs(incompleteWork!.deadline).isToday()
                        ? COLORS.brand1
                        : dayjs(incompleteWork!.deadline).isBefore(dayjs())
                        ? COLORS.negative1
                        : COLORS.gray600,
                      0.3,
                    ),
                  },
                }}
                style={deadlineAnchorEl ? { display: 'flex' } : {}}
              >
                <Icons.Close
                  width={8}
                  height={8}
                  stroke={
                    dayjs(incompleteWork!.deadline).isToday()
                      ? COLORS.brand1
                      : dayjs(incompleteWork!.deadline).isBefore(dayjs())
                      ? COLORS.negative1
                      : COLORS.gray600
                  }
                />
              </IconButton>
            </DeadlineShowingWrapper>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              language === 'ko' ? '기한 설정하기' : 'Set deadline'
              // 'Set deadline'
            }
            disableInteractive
          >
            <IconButton
              onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}
              sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
            >
              <Icons.Flag />
            </IconButton>
          </Tooltip>
        )}
      </IncompletePropertyWrapper>
      <IncompleteWorkToDo>
        {!incompleteWork!.done && incompleteWork!.tasks!.filter((v) => v.done).length > 0 ? (
          <div onClick={() => onClickCheckbox(incompleteWork!)} style={{ cursor: 'pointer' }}>
            <Icons.InprogressCheck width={20} height={20} />
          </div>
        ) : incompleteWork!.done ? (
          <div onClick={() => onClickCheckbox(incompleteWork!)} style={{ cursor: 'pointer' }}>
            <Icons.PriorityIssueCheck width={20} height={20} />
          </div>
        ) : (
          <div onClick={() => onClickCheckbox(incompleteWork!)} style={{ cursor: 'pointer' }}>
            <Icons.IssueCheckBox />
          </div>
        )}
        <TextField
          inputRef={refInput}
          autoComplete="off"
          fullWidth
          variant="standard"
          placeholder={language === 'ko' ? '태스크박스 제목을 입력하세요' : 'Enter the taskbox title'}
          onKeyDown={handleKeydownInput}
          onBlur={handleBlurInput}
          onChange={(e) => {
            handleIncompleteWorkTitle(e);
          }}
          defaultValue={incompleteWork ? incompleteWork?.title : ''}
          InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
          style={{ marginLeft: 8, width: '99%' }}
        />
        {incompleteWork?.id && (
          <IconButton onClick={(e) => setIncompleteWorkDetail(e.currentTarget)} style={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }}>
            <Icons.More width={16} height={16} fill={COLORS.gray300} />
          </IconButton>
        )}
      </IncompleteWorkToDo>
      <div style={{ paddingLeft: '4px' }}>
        <TaskBlockInputList
          ref={refTaskBlockList}
          suppressAddTime
          categoryList={categoryList}
          tasks={(incompleteWork?.tasks as TaskBlock[]) || []}
          onChange={onChangeTaskBlocks}
          onClickCategory={handleClickCategory}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
        <Button
          variant="contained"
          onClick={() => {
            onClickAddTaskbox(incompleteWork!);
          }}
          sx={{ borderRadius: '8px', textTransform: 'none' }}
        >
          {language === 'ko' ? '할 일에 추가하기' : 'Add to To-Do'}
        </Button>
      </div>
      <Popover
        open={Boolean(incompleteWorkDetail)}
        anchorEl={incompleteWorkDetail}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseIncompleteWorkPopover}
        sx={{ marginTop: '4px' }}
      >
        <DetailOptionsModal>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              onClickLater(incompleteWork!);
            }}
            style={{ padding: '8px ' }}
          >
            <Icons.Later width={16} height={16} />
            <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '나중에' : 'Later'}</span>
          </Fonts.Blockquote>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              setDeleteIncomplete(true);
            }}
            style={{ color: `${COLORS.negative1}`, padding: '8px' }}
          >
            <Icons.Delete fill={COLORS.negative1} />
            <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '삭제' : 'Delete'}</span>
          </Fonts.Blockquote>
        </DetailOptionsModal>
      </Popover>
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
      {/* 태스크박스 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleClickDeadline} />
        </Popover>
      )}
      <Dialog
        open={openIncompleteDeleteDialog}
        onClose={() => {
          setDeleteIncomplete(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}`, textAlign: 'center' }}>
          {language === 'ko' ? '미완료 업무를 삭제하시겠어요?' : 'Delete incomplete tasks?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteIncomplete(false);
            }}
            sx={{ textTransform: 'none' }}
          >
            {language === 'ko' ? '취소' : 'Cancel'}
          </Button>
          <Button
            onClick={() => {
              setDeleteIncomplete(false);
              setIncompleteWorkDetail(null);
              setTimeout(() => {
                onClickDelete(incompleteWork!);
              }, 200);
            }}
            sx={{ textTransform: 'none' }}
          >
            {language === 'ko' ? '삭제' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </IncompleteWorkDialogWrapper>
  );
};

const IncompleteWorkDialogWrapper = styled.div`
  padding: 24px 22px 24px 0px;
  width: 540px;
`;

const IncompleteWorkToDo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 24px;
`;

const IncompleteWorkDialogBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DetailOptionsModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 8px;
  z-index: 10;

  .detail-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;
