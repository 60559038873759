import { useEffect, useRef, useState } from 'react';
import { TextField, InputAdornment, Autocomplete, Box, PopperProps, Popper, IconButton, duration, Button, Popover } from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, TIME_FORMAT_2, DATE_FORMAT_7, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { InCreateTaskBoxInRecurrenceTarget, OutTaskboxDetailResponse, InUpdateRoutineRuleOptionalTime } from 'queries/model';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { UpdateRecurrenceDialog } from 'components/Recurrence/UpdateRecurrenceDialog';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { DateType } from 'pages/Task/components/TimelineTaskbox';

interface Props {
  event?: OutTaskboxDetailResponse;
  autoSetting?: boolean;
  isNotPopover?: boolean;
  onClose?: () => void;
  onChangeTime?: (
    startTime: string,
    endTime: string,
    timeDiff: number,
    eventId?: string,
    isAllDay?: boolean,
    taskboxRecurrence?: InUpdateRoutineRuleOptionalTime,
  ) => void;
  onChangeDate?: (date: Date | null, isAllDay?: boolean, dateType?: DateType) => void;
}

export const SimpleTimeSelectionModal = ({ event, autoSetting, isNotPopover = false, onClose = () => {}, onChangeTime = () => {}, onChangeDate }: Props) => {
  const [language] = useAtom(languageAtom);
  const [startTime, setStartTime] = useState<string | undefined>(event?.start?.datetime ? event?.start?.datetime : undefined);
  const [endTime, setEndTime] = useState<string | undefined>(event?.end?.datetime ? event?.end?.datetime : undefined);
  const [isAllDay, setIsAllDay] = useState<boolean | undefined>(event?.allDay);
  const [isOpenRecurrenceDialog, setIsOpenRecurrenceDialog] = useState<boolean>(false);
  const [timeDiff, setTimeDiff] = useState(
    event?.durationMin ? event.durationMin : event?.allDay ? undefined : dayjs(event?.end?.datetime).diff(dayjs(event?.start?.datetime), 'minute'),
  );
  const [inputTimeSelectValue, setInputTimeSelectValue] = useState(startTime ? dayjs(startTime, { utc: true }).format(TIME_FORMAT_2) : '');
  const [timeError, setTimeError] = useState(false);
  const [openStartTime, setOpenStartTime] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [durationLimitError, setDurationLimitError] = useState(false);
  const [openDurationTime, setOpenDurationTime] = useState(false);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(null);
  const refTimeSelection = useRef<HTMLDivElement>(null);
  const refPopper = useRef<HTMLDivElement>(null);
  const refStartTimeInput = useRef<HTMLInputElement>(null);
  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs()
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(TIME_FORMAT_2),
    ),
  ];
  const durationToshowingTime = (duration?: number) => {
    if (!duration) return;
    const hour = Math.floor(duration / 60);
    const minute = duration % 60;

    if (hour === 0) {
      return language === 'ko' ? `${minute}분` : `${minute}min`;
      // return `${minute}min`;
    } else if (minute === 0) {
      return language === 'ko' ? `${hour}시간` : `${hour}hr`;
      // return `${hour}hr`;
    } else {
      return language === 'ko' ? `${hour}시간 ${minute}분` : `${hour}hr ${minute}min`;
      // return `${hour}hr ${minute}min`;
    }
  };
  const selectableTimeDuration = [15, 30, 45, 60, 90, 120, 150, 180].map((v) => durationToshowingTime(v));
  const [inputDurationSelectValue, setInputDurationSelectValue] = useState(() => durationToshowingTime(timeDiff));

  useEffect(() => {
    setStartTime(event?.start?.datetime ? event?.start.datetime : undefined);
    setEndTime(event?.end?.datetime ? event?.end?.datetime : undefined);
    setTimeDiff(
      event?.durationMin
        ? event.durationMin
        : event?.allDay || event?.start?.date
        ? undefined
        : dayjs(event?.end?.datetime).diff(dayjs(event?.start?.datetime), 'minute'),
    );
  }, [event]);

  useEffect(() => {
    if (inputTimeSelectValue) formatDateTime(inputTimeSelectValue);
  }, [inputTimeSelectValue]);

  useEffect(() => {
    showingTimeToDuration(inputDurationSelectValue);
  }, [inputDurationSelectValue]);

  useEffect(() => {
    if (autoSetting) {
      const startTime =
        dayjs(event?.start?.date || event?.start?.datetime, { utc: true })
          .set('hour', dayjs().toDate().getHours())
          .set('minute', dayjs().toDate().getMinutes())
          .ceil('minutes', 15) >= dayjs().set('hour', 23).set('minute', 45)
          ? dayjs(event?.start?.date || event?.start?.datetime, { utc: true })
              .set('hour', 23)
              .set('minute', 45)
              .format(DATE_FORMAT_1)
          : dayjs(event?.start?.date || event?.start?.datetime, { utc: true })
              .set('hour', dayjs().toDate().getHours())
              .set('minute', dayjs().toDate().getMinutes())
              .ceil('minutes', 15)
              .format(DATE_FORMAT_1);
      setStartTime(startTime);
      setEndTime(dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1));
      setTimeDiff(60);
      setIsAllDay(false);
      setTimeout(() => {
        setOpenStartTime(true);
        refStartTimeInput.current?.focus();
      }, 300);
    }
  }, [autoSetting]);

  useEffect(() => {
    if (openStartTime && !startTime) {
      const currentTime =
        dayjs().set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15) >=
        dayjs().set('hour', 23).set('minute', 45)
          ? dayjs().set('hour', 23).set('minute', 45).format(DATE_FORMAT_7)
          : dayjs().set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15).format(DATE_FORMAT_7);

      const index = selectableTime.findIndex((time) => time === dayjs(currentTime).format(TIME_FORMAT_2));

      setTimeout(() => {
        if (index === -1) return;
        const target = document.querySelector(`[data-option-index="${index}"]`);
        if (target) {
          target.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 100);
    }
  }, [openStartTime]);

  useUpdateEffect(() => {
    if (!timeDiff) {
      if (event?.type === 'ROUTINE_TASK') {
        if (startTime) setTimeDiff(60);
        else setEndTime(undefined);
      }
      return;
    }

    setEndTime(startTime ? dayjs(startTime).add(timeDiff, 'minute').format(DATE_FORMAT_7) : undefined);
  }, [startTime]);

  useUpdateEffect(() => {
    if (!timeDiff) return;
    if (startTime) setEndTime(dayjs(startTime).add(timeDiff, 'minute').format(DATE_FORMAT_7));
  }, [timeDiff]);

  useClickOutside(refTimeSelection, (e) => {
    if (refPopper.current) {
      if (refPopper.current.contains(e.target as Node)) return;
      setOpenStartTime(false);
      setOpenDurationTime(false);
      return;
    }
    event?.isRecurrence ? handleOpenRecurrenceDialog() : handleClickSave();
  });

  useKeyboardEvent(
    true,
    (ev) => {
      if (isNotPopover) return;
      if (ev.key === 'Escape') {
        if (+dayjs(startTime) >= +dayjs(endTime)) {
          language === 'ko' ? toast.error('시작 시간이 종료 시간보다 늦을 수 없습니다.') : toast.error(`Start time can't be later than end time.`);
          // toast.error(`Start time can't be later than end time.`);
          return;
        }

        if (dayjs(event?.start?.datetime).isSame(startTime) && dayjs(event?.end?.datetime).isSame(endTime)) {
          onClose();
          return;
        }

        onClose();
        onChangeTime(
          dayjs(startTime, { utc: true }).format(DATE_FORMAT_1),
          dayjs(endTime, { utc: true }).format(DATE_FORMAT_1),
          timeDiff!,
          event!.id!,
          !startTime && !endTime && timeDiff ? true : isAllDay,
        );
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleClickSave = async (target?: InCreateTaskBoxInRecurrenceTarget) => {
    if (event?.type?.includes('ROUTINE') && !startTime && !endTime) {
      onClose();
      return;
    }

    if (
      (dayjs(event?.start?.datetime).isSame(dayjs(startTime)) && dayjs(event?.end?.datetime).isSame(dayjs(endTime)) && !event?.allDay) ||
      (event?.allDay && event?.durationMin === timeDiff && !startTime && !endTime)
    ) {
      onClose();
      return;
    }

    if (startTime && !timeDiff) {
      language === 'ko' ? toast.error('지속 시간을 설정해주세요.') : toast.error('Set duration');
      // toast.error('Set duration');
      return;
    }

    const newRecurrence: InUpdateRoutineRuleOptionalTime | undefined = event?.recurrence
      ? {
          recurrence: event?.recurrence,
          start:
            !startTime && !endTime
              ? { date: dayjs(event?.start?.date || dayjs(event?.start?.datetime), { utc: true }).format(DATE_FORMAT_4) }
              : { datetime: dayjs(startTime, { utc: true }).format(DATE_FORMAT_1) },
          end:
            !startTime && !endTime
              ? {
                  date: dayjs(event?.start?.date || dayjs(event?.start?.datetime, { utc: true }))
                    .add(1, 'day')
                    .format(DATE_FORMAT_4),
                }
              : { datetime: dayjs(endTime, { utc: true }).format(DATE_FORMAT_1) },
        }
      : undefined;

    onChangeTime(
      dayjs(startTime, { utc: true }).format(DATE_FORMAT_1),
      dayjs(endTime, { utc: true }).format(DATE_FORMAT_1),
      timeDiff!,
      event!.id!,
      !startTime && !endTime && timeDiff ? true : isAllDay,
      newRecurrence,
    );
    onClose();
  };

  const handleOpenRecurrenceDialog = () => {
    if (dayjs(event?.start?.datetime).isSame(startTime) && dayjs(event?.end?.datetime).isSame(endTime)) {
      onClose();
      return;
    }
    setIsOpenRecurrenceDialog(true);
  };

  const handleCloseRecurrenceDialog = () => {
    onClose();
    setIsOpenRecurrenceDialog(false);
  };

  /** 시작일 설정 로직 */
  //유저가 시간 타이핑하면 그 시간으로 바꿔주기(onInputChange)
  const handleInputTimeSelectValue = (value: string) => {
    setInputTimeSelectValue(value);
  };

  //유저가 시간 클릭/입력 시 그 시간으로 바꿔주기(onChange) 오후 01:00 -> DATE_FORMAT_1형태로 변경
  const formatDateTime = (value: string) => {
    const isPM = language === 'ko' ? value.includes('오후') : value.includes('pm');
    const isAM = language === 'ko' ? value.includes('오전') : value.includes('am');
    let timeString = language === 'ko' ? value.replace(/(오전|오후|:|\s)/g, '') : value.replace(/(am|pm|:|\s)/g, '');
    // const isPM = value.includes('pm');
    // const isAM = value.includes('am');
    // let timeString = value.replace(/(am|pm|:|\s)/g, '');

    // 오전 12시를 00시로 변경
    if (isAM && timeString.startsWith('12')) {
      timeString = timeString.replace('12', '00');
    }

    let convertedTime = '';

    if (Number(timeString)) {
      setTimeError(timeString.length > 4);
      convertedTime = convertToTime(Number(timeString));
    } else {
      language === 'ko'
        ? setTimeError(timeString !== '' || value.includes('오후') || value.includes('오전'))
        : setTimeError(timeString !== '' || value.includes('pm') || value.includes('am'));
      // setTimeError(timeString !== '' || value.includes('pm') || value.includes('am'));
    }

    const isAfterNoon = dayjs(convertedTime, 'HH:mm').isAfter(dayjs('12:00', 'HH:mm')) || dayjs(convertedTime, 'HH:mm').isSame(dayjs('12:00', 'HH:mm'));

    const startDateTime = event?.start?.datetime
      ? dayjs(event?.start?.datetime, { utc: true }).format(DATE_FORMAT_1)
      : dayjs(event?.start?.date).format(DATE_FORMAT_1);

    const [datePart, timePart] = startDateTime.split('T');

    // timePart에서 + 또는 -를 추출하고, 이를 timezoneSign에 저장
    const timezoneSign = timePart.includes('-') ? '-' : '+';
    const timezoneOffset = timePart.split(/[+-]/)[1] || '00:00';

    // timezoneSign을 사용해 formattedDateTime 생성
    const formattedDateTime = `${datePart}T${convertedTime}:00${timezoneSign}${timezoneOffset}`;

    return isPM && !isAfterNoon
      ? dayjs(formattedDateTime, { utc: true }).add(12, 'hour').format(DATE_FORMAT_1)
      : dayjs(formattedDateTime, { utc: true }).format(DATE_FORMAT_1);
  };

  // 0100 -> 01:00형태로 변경
  const convertToTime = (time: number): string => {
    const timeStr = time.toString().padStart(4, '0');
    const hours = parseInt(timeStr.slice(0, 2), 10);
    const minutes = parseInt(timeStr.slice(2), 10);
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleStartTimeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setOpenStartTime(true);

    if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
      e.stopPropagation();
      (document.activeElement as HTMLElement).blur();
      // if (timeError || inputTimeSelectValue === '') {
      //   setStartTime(startTime);
      //   setInputTimeSelectValue(dayjs(startTime, { utc: true }).format(TIME_FORMAT_2));
      // } else {
      //   if (!inputTimeSelectValue) return;
      //   setStartTime(formatDateTime(inputTimeSelectValue));
      // }
      // if (openStartTime) setOpenStartTime(false);
    }

    // if (e.key === 'Escape') {
    //   e.stopPropagation();
    //   setOpenStartTime(false);
    //   (document.activeElement as HTMLElement).blur();
    // }
  };

  /**Duration 시간 설정 */
  const handleInputDurationSelectValue = (value: string) => {
    setInputDurationSelectValue(value);
  };

  // '1시간' -> 60형태로 변환
  const showingTimeToDuration = (showingTime?: string) => {
    if (!showingTime) return;
    const durationString = language === 'ko' ? showingTime.replace(/(시간|시|분|\s)/g, '') : showingTime.replace(/(hr|h|min|m|\s)/g, '');
    // const durationString = showingTime.replace(/(hr|h|min|m|\s)/g, '');

    if (isNaN(Number(durationString)) || Number(durationString) <= 0) {
      setDurationError(true);
      return;
    }

    if (durationError) setDurationError(false);

    const matchHourMinute = language === 'ko' ? showingTime.match(/(\d*)?\s*시간?\s*(\d*)?\s*분?/) : showingTime.match(/(\d+)\s*(hr|h)\s*(\d+)?\s*(min|m)?/);
    const matchMinute = language === 'ko' ? showingTime.match(/\s*(\d*)\s*분?/) : showingTime.match(/\s*(\d*)\s*(min|m)?/);

    let duration = 0;
    if (matchHourMinute) {
      const hours = parseInt(matchHourMinute[1], 10) || 0;
      const minutes = language === 'ko' ? parseInt(matchHourMinute[2], 10) || 0 : parseInt(matchHourMinute[3], 10) || 0;
      // const minutes = parseInt(matchHourMinute[3], 10) || 0;
      duration = hours * 60 + minutes;
    } else if (matchMinute) {
      const minutes = parseInt(matchMinute[1], 10) || 0;
      duration = minutes;
    }

    setDurationLimitError(duration > 540);
    return duration;
  };

  const handleDurationTimeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
      e.stopPropagation();
      (document.activeElement as HTMLElement).blur();
      // if (durationError || durationLimitError || inputDurationSelectValue === '') {
      //   setEndTime(endTime);
      //   setInputDurationSelectValue(durationToshowingTime(timeDiff));
      // } else {
      //   setTimeDiff(showingTimeToDuration(inputDurationSelectValue)!);
      // }
      // setOpenDurationTime(false);
    }

    // if (e.key === 'Escape') {
    //   e.stopPropagation();
    //   setOpenDurationTime(false);
    //   (document.activeElement as HTMLElement).blur();
    // }
  };

  const handleRemoveStartTime = () => {
    setStartTime(undefined);
    setIsAllDay(true);
  };

  const handleRemoveDurationTime = () => {
    setStartTime(undefined);
    setEndTime(undefined);
    setTimeDiff(undefined);
    setIsAllDay(true);
  };

  const handleCloseCalendar = () => {
    setCalendarAnchorEl(null);
  };

  return (
    <Container ref={refTimeSelection} isNotPopover={isNotPopover}>
      {!isNotPopover && (
        <Button
          disabled={autoSetting}
          endIcon={autoSetting ? null : calendarAnchorEl ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
          onClick={(e) => setCalendarAnchorEl(e.currentTarget)}
          sx={{
            'color': COLORS.gray900,
            'fontSize': '12px',
            'fontWeight': autoSetting ? 400 : 700,
            'marginBottom': '8px',
            'padding': '0px',
            ':hover': {
              backgroundColor: 'transparent',
            },
            '.MuiButton-endIcon': {
              marginLeft: '4px',
              marginBottom: '1px',
            },
          }}
        >
          {startTime
            ? dayjs(startTime, { utc: true }).format(language === 'ko' ? 'M월 D일 ddd요일' : 'ddd, MMM D')
            : dayjs(event?.start?.date, { utc: true }).format(language === 'ko' ? 'M월 D일 ddd요일' : 'ddd, MMM D')}
        </Button>
      )}
      <TimeSelectPopoverWrapper timeError={timeError} durationError={durationError || durationLimitError}>
        <Icons.Time />
        <StartTimeWrapper>
          <Autocomplete
            className="auto-complete"
            freeSolo
            open={openStartTime}
            value={startTime ? dayjs(dayjs(startTime, { utc: true })).format(TIME_FORMAT_2) : ''}
            inputValue={inputTimeSelectValue || ''}
            placeholder={
              language === 'ko' ? '시작 시간' : 'Start time'
              // 'Start time'
            }
            onChange={(e, newValue) => {
              if (!newValue) return;
              (document.activeElement as HTMLElement).blur();
              setStartTime(formatDateTime(newValue!));
              setOpenStartTime(false);
              setIsAllDay(false);
            }}
            onInputChange={(e, newValue) => {
              handleInputTimeSelectValue(newValue);
            }}
            onKeyDown={(e) => {
              handleStartTimeKeyDown(e);
            }}
            onFocus={() => {
              setOpenStartTime(true);
            }}
            onBlur={() => {
              if (timeError || inputTimeSelectValue === '') {
                setStartTime(startTime);
                setInputTimeSelectValue(dayjs(startTime, { utc: true }).format(TIME_FORMAT_2));
              } else {
                if (!inputTimeSelectValue) return;
                setStartTime(formatDateTime(inputTimeSelectValue));
                setIsAllDay(false);
              }
              setOpenStartTime(false);
            }}
            options={selectableTime}
            isOptionEqualToValue={(option, value) => option === value}
            size="small"
            PopperComponent={(props: PopperProps) => (
              <Popper
                {...props}
                ref={refPopper}
                placement="bottom"
                sx={{
                  '&.MuiAutocomplete-noOptions': {
                    display: 'none',
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            ListboxProps={{
              style: {
                display: timeError ? 'none' : 'block',
                maxHeight: '264px',
                fontSize: '12px',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={refStartTimeInput}
                placeholder={
                  language === 'ko' ? '시작 시간' : 'Start time'
                  // 'Start time'
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: startTime && (
                    <InputAdornment
                      position={
                        language === 'ko' ? 'end' : 'start'
                        // 'start'
                      }
                      disablePointerEvents
                      disableTypography
                      sx={{ fontSize: '12px', color: COLORS.gray500, cursor: 'pointer', paddingTop: '2px' }}
                    >
                      {language === 'ko' ? '부터' : 'From'}
                      {/* from */}
                    </InputAdornment>
                  ),
                  style: {
                    height: '34px',
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
              />
            )}
            sx={{ width: '100px', marginLeft: '8px' }}
          />
          {startTime && (
            <IconButton
              className="start-close-button"
              onClick={handleRemoveStartTime}
              sx={{
                'width': '16px',
                'height': '16px',
                'backgroundColor': COLORS.gray600,
                'opacity': 0,
                'padding': '0px',
                'position': 'absolute',
                'top': '-6px',
                'left': '99px',
                ':hover': {
                  backgroundColor: COLORS.gray900,
                },
              }}
            >
              <Icons.Close width={10} height={10} stroke={COLORS.white} />
            </IconButton>
          )}
        </StartTimeWrapper>
        <DurationTimeWrapper>
          <Autocomplete
            className="auto-complete"
            freeSolo
            open={openDurationTime}
            value={timeDiff ? durationToshowingTime(timeDiff) : ''}
            inputValue={inputDurationSelectValue || ''}
            placeholder={
              language === 'ko' ? '지속 시간' : 'Duration'
              // 'Duration'
            }
            onChange={(e, newValue) => {
              if (!newValue) return;
              (document.activeElement as HTMLElement).blur();
              setTimeDiff(showingTimeToDuration(newValue)!);
              setOpenDurationTime(false);
              setIsAllDay(false);
            }}
            // inputValue={inputTimeSelectValue}
            onInputChange={(e, newValue) => {
              handleInputDurationSelectValue(newValue);
            }}
            onKeyDown={(e) => {
              handleDurationTimeKeyDown(e);
            }}
            onFocus={() => {
              setOpenDurationTime(true);
            }}
            onBlur={() => {
              if (durationError || durationLimitError || inputDurationSelectValue === '') {
                setEndTime(endTime);
                setInputDurationSelectValue(durationToshowingTime(timeDiff));
              } else {
                setTimeDiff(showingTimeToDuration(inputDurationSelectValue)!);
                setIsAllDay(false);
              }
              setOpenDurationTime(false);
            }}
            options={selectableTimeDuration}
            isOptionEqualToValue={(option, value) => option === value}
            PopperComponent={(props: PopperProps) => (
              <Popper
                {...props}
                ref={refPopper}
                placement="bottom"
                sx={{
                  '&.MuiAutocomplete-noOptions': {
                    display: 'none',
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  language === 'ko' ? '지속 시간' : 'Duration'
                  // 'Duration'
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: timeDiff && (
                    <InputAdornment
                      position={
                        language === 'ko' ? 'end' : 'start'
                        // 'start'
                      }
                      disablePointerEvents
                      disableTypography
                      sx={{ fontSize: '12px', color: COLORS.gray500, cursor: 'pointer', paddingTop: '2px' }}
                    >
                      {language === 'ko' ? '동안' : 'for'}
                      {/* for */}
                    </InputAdornment>
                  ),
                  style: {
                    height: '34px',
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                fontSize: '12px',
              },
            }}
            sx={{ width: '100px' }}
          />
          {timeDiff && (
            <IconButton
              className="duration-close-button"
              onClick={handleRemoveDurationTime}
              sx={{
                'width': '16px',
                'height': '16px',
                'backgroundColor': COLORS.gray600,
                'opacity': 0,
                'padding': '0px',
                'position': 'absolute',
                'top': '-6px',
                'left': '91px',
                ':hover': {
                  backgroundColor: COLORS.gray900,
                },
              }}
            >
              <Icons.Close width={10} height={10} stroke={COLORS.white} />
            </IconButton>
          )}
        </DurationTimeWrapper>
        {/* {(timeError || durationError || durationLimitError) && (
          <SelectErrorWrapper timeError={timeError} durationError={durationError} durationLimitError={durationLimitError}>
            {durationLimitError ? '9시간까지 지정 가능' : '잘못된 시간'}
          </SelectErrorWrapper>
        )} */}
      </TimeSelectPopoverWrapper>
      <Popover
        disablePortal
        open={Boolean(calendarAnchorEl)}
        anchorEl={calendarAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleCloseCalendar}
        sx={{ marginTop: '2px' }}
      >
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={event?.start?.date || event?.start?.datetime}
          onChange={(newValue) => onChangeDate?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate(), true, 'calendar')}
          renderInput={(params) => <TextField {...params} />}
        />
      </Popover>
      <UpdateRecurrenceDialog open={isOpenRecurrenceDialog} onClose={handleCloseRecurrenceDialog} onUpdateRecurrence={handleClickSave} />
    </Container>
  );
};

const Container = styled.div<{ isNotPopover?: boolean }>`
  z-index: 100;
  border-radius: 8px;
  padding: 16px;
  ${(props) => props.isNotPopover && 'padding: 0px;'}

  .MuiMenu-paper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }

  .item-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }

  .MuiPickersCalendarHeader-labelContainer {
    max-height: 40px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 100%;
  }

  .css-epd502 {
    width: 100%;
  }

  .MuiCalendarPicker-root {
    width: 100%;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .MuiPickersDay-root.MuiPickersDay-today {
    background-color: ${COLORS.brand1};
    color: ${COLORS.white};
  }

  .css-czqvw5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-czqvw5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-czqvw5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 0px;
  }

  .auto-complete {
    .MuiInputBase-input {
      padding: 0px !important;
    }
  }

  .time-select {
    .MuiSvgIcon-root {
      display: none;
    }
  }

  .recurrence {
    .MuiSvgIcon-root {
      display: none;
    }
  }
`;

const TimeSelectPopoverWrapper = styled.div<{ timeError?: boolean; durationError?: boolean }>`
  display: flex;
  align-items: center;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.timeError && `border-color: ${COLORS.negative1};`}
    ${(props) => props.durationError && `border-color: ${COLORS.negative1};`}
  }

  .MuiAutocomplete-noOptions {
    display: 'none';
  }
`;

const StartTimeWrapper = styled.div`
  position: relative;
  :hover {
    .start-close-button {
      opacity: 1;
    }
  }
`;

const DurationTimeWrapper = styled.div`
  position: relative;
  margin-left: 8px;
  :hover {
    .duration-close-button {
      opacity: 1;
    }
  }
`;
