import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { createVoteV1VotesPost, getListVotesV1VotesGet } from 'queries';
import { CreateVote } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { Vote } from './Vote';
import { v4 as uuidv4 } from 'uuid';
import { CompleteVote } from './CompleteVote';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface ProductionPlanProps {
  suppressBeta?: boolean;
  onStart?: () => void;
}

export const ProductionPlan = ({ suppressBeta = false, onStart }: ProductionPlanProps) => {
  const [language] = useAtom(languageAtom);
  const [openVote, setOpenVote] = useState(false);
  const [openCompleteVote, setOpenCompleteVote] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [isImgRendered, setIsImgRendered] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    init();
    const img = new Image();
    img.src = require('assets/images/plan.png');
    img.onload = () => {
      setIsImgLoaded(true);
    };
  }, []);

  const init = async () => {
    const voteList = await getListVotesV1VotesGet();
    voteList.find((item) => item.voteType === 'plan') ? setComplete(true) : setComplete(false);
  };

  const handleOpenVote = () => {
    setOpenVote(true);
  };
  const handleCloseVote = () => {
    setOpenVote(false);
  };

  const handleOpenCompleteVote = () => {
    setOpenCompleteVote(true);
  };

  const handleCloseCompleteVote = () => {
    setOpenCompleteVote(false);
  };

  const handleClickVote = async (score: number, description: string) => {
    const newVote: CreateVote = {
      id: uuidv4(),
      voteType: 'plan',
      score,
      description,
    };

    const success = await createVoteV1VotesPost(newVote);

    if (success) {
      setOpenVote(false);
      setTimeout(() => {
        handleOpenCompleteVote();
      }, 300);
      init();
    }
  };

  return (
    <Container>
      {isImgLoaded && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: isImgRendered ? 1 : 0 }}>
          <MainContent>
            {!suppressBeta && (
              <MainContentHeader>
                <div
                  style={{
                    width: '14px',
                    height: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: COLORS.brand1,
                    borderRadius: '50%',
                    marginRight: '4px',
                  }}
                >
                  <Icons.Preparing />
                </div>
                <Fonts.H4>준비중</Fonts.H4>
              </MainContentHeader>
            )}

            <MainContentMain>
              <Fonts.H1>
                {language === 'ko'
                  ? `하루 계획과 중장기 프로젝트, 더 이상 따로 작성할 필요 없어요`
                  : `No more separate planning for daily tasks and long-term projects.`}
              </Fonts.H1>
            </MainContentMain>
            <MainContentFooter>
              <Fonts.Body1>
                {language === 'ko'
                  ? `진행중인 프로젝트를 오늘 할 일 목록으로 가져오면 끝\n'중요한 것'과 '급한 것'의 균형을 찾을 수 있어요`
                  : `Just move ongoing projects to today’s task list\nand you’ll find balance between "Important" and "Urgent"`}
              </Fonts.Body1>
            </MainContentFooter>
          </MainContent>

          {!suppressBeta ? (
            <>
              {complete === false && (
                <Button variant="contained" onClick={handleOpenVote} sx={{ height: '40px', padding: '10px 16px', borderRadius: '8px', margin: '32px 0px' }}>
                  👍 기대돼요
                </Button>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              onClick={onStart}
              sx={{ height: '40px', padding: '10px 16px', borderRadius: '8px', margin: '32px 0px', textTransform: 'none' }}
            >
              <Icons.Plus width={16} height={16} stroke={COLORS.white} strokeWidth={2} />
              <span style={{ marginLeft: 8 }}>{language === 'ko' ? '프로젝트 추가하기' : 'Add project'}</span>
            </Button>
          )}

          {complete && (
            <div
              style={{
                padding: '10px 16px',
                color: COLORS.brand1,
                fontSize: '13px',
                fontWeight: 'bold',
                borderRadius: '8px',
                border: `1px solid ${COLORS.brand1}`,
                backgroundColor: COLORS.white,
                margin: '32px 0px',
              }}
            >
              👍 기대돼요 전달완료!
            </div>
          )}
          <img
            src={require(language === 'ko' ? 'assets/images/plan.png' : 'assets/images/plan_en.png')}
            alt="goal-img"
            style={{ width: 827 }}
            onLoad={() => setIsImgRendered(true)}
          />
        </div>
      )}

      <Vote open={openVote} onClose={handleCloseVote} onClick={handleClickVote} />
      <CompleteVote open={openCompleteVote} onClose={handleCloseCompleteVote} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainContentHeader = styled.div`
  color: ${COLORS.brand1};
  background-color: ${COLORS.sub3};
  padding: 4px 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
`;

const MainContentMain = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 12px;
  text-align: center;
  white-space: pre-line;
`;

const MainContentFooter = styled.div`
  color: ${COLORS.gray500};
  white-space: pre-line;
  text-align: center;
`;

export default ProductionPlan;
