import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconButton, MenuItem, Select, Switch } from '@mui/material';
import { IconBase } from '@remirror/react';
import { Icons } from 'components';
import AntSwitch from 'components/AntSwitch';
import dayjs from 'lib/dayjs';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { DATE_FORMAT_4, DATE_FORMAT_7, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { UserSettingModel } from 'queries/model';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

interface NotificationProps {
  setting?: UserSettingModel;
  onChange?: (notificationData: UserSettingModel) => void;
}

const NotificationSetting = ({ setting, onChange }: NotificationProps) => {
  const [notiSetting, setNotiSetting] = useState<UserSettingModel>();

  useEffect(() => {
    if (setting) {
      const { recordAlarmTimeCollection } = setting;
      const timeArr = recordAlarmTimeCollection ? convertTime(recordAlarmTimeCollection) : convertTime(['17:00']);
      setNotiSetting({ ...setting, recordAlarmTimeCollection: timeArr });
    }
  }, [setting]);

  const convertTime = (time: string[]) => {
    const timeArr: string[] = [];
    time.map((v) => {
      timeArr.push(dayjs(`${dayjs().format(DATE_FORMAT_4)} ${v}`).format(DATE_FORMAT_7));
    });

    return timeArr;
  };

  const handleClickExtraNotificationTime = () => {
    setNotiSetting({
      ...notiSetting,
      recordAlarmTimeCollection: [...notiSetting!.recordAlarmTimeCollection!, dayjs().ceil('minutes', 15).format(DATE_FORMAT_7)],
    });
  };

  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs()
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(DATE_FORMAT_7),
    ),
  ];

  const handleChangeFocusTime = () => {
    setNotiSetting({ ...notiSetting, isFocusAlarmSet: !notiSetting?.isFocusAlarmSet });
    onChange &&
      onChange({
        ...notiSetting,
        isFocusAlarmSet: !notiSetting?.isFocusAlarmSet,
        recordAlarmTimeCollection: notiSetting!.recordAlarmTimeCollection!.map((v) => dayjs(v).format('HH:mm')),
      });
  };

  const handleChangeOrganizeToday = () => {
    setNotiSetting({ ...notiSetting, isRecordAlarmSet: !notiSetting?.isRecordAlarmSet });
    onChange &&
      onChange({
        ...notiSetting,
        isRecordAlarmSet: !notiSetting?.isRecordAlarmSet,
        recordAlarmTimeCollection: notiSetting!.recordAlarmTimeCollection!.map((v) => dayjs(v).format('HH:mm')),
      });
  };

  const handleChangeNotiTime = (time: string, index: number) => {
    const newNotiTime = [...notiSetting!.recordAlarmTimeCollection!];
    newNotiTime[index] = time;
    setNotiSetting({ ...notiSetting, recordAlarmTimeCollection: newNotiTime });
    onChange && onChange({ ...notiSetting, recordAlarmTimeCollection: newNotiTime.map((v) => dayjs(v).format('HH:mm')) });
  };

  const handleClickDeleteTime = () => {
    const newNotiTime = [...notiSetting!.recordAlarmTimeCollection!];
    newNotiTime.pop();
    setNotiSetting({ ...notiSetting, recordAlarmTimeCollection: newNotiTime });
    onChange && onChange({ ...notiSetting, recordAlarmTimeCollection: newNotiTime.map((v) => dayjs(v).format('HH:mm')) });
  };

  const { t } = useTranslation();

  return (
    <Container>
      <NotificationWrapper>
        <NotificationTitle>{t('settings.highlight.header')}</NotificationTitle>
        <NotificationSettingWrapper>
          <NotificationSettingContent>
            <Icons.FocusTime />
            <NotificationSettingCotentText>
              <div style={{ fontSize: '13px', fontWeight: 700, marginBottom: 4 }}>{t('settings.highlight.title')}</div>
              <div style={{ fontSize: '12px', color: COLORS.gray500 }}>{t('settings.highlight.description')}</div>
            </NotificationSettingCotentText>
          </NotificationSettingContent>
          <AntSwitch checked={notiSetting?.isFocusAlarmSet} onChange={handleChangeFocusTime} />
        </NotificationSettingWrapper>
      </NotificationWrapper>
      <NotificationWrapper style={{ marginTop: '56px' }}>
        <NotificationTitle>{t('settings.organize.header')}</NotificationTitle>
        <NotificationSettingWrapper style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <NotificationSettingContent>
              <Icons.OrganizeToday />
              <NotificationSettingCotentText>
                <div style={{ fontSize: '13px', fontWeight: 700, marginBottom: 4 }}>{t('settings.organize.title')}</div>
                <div style={{ fontSize: '12px', color: COLORS.gray500 }}>{t('settings.organize.description')}</div>
              </NotificationSettingCotentText>
            </NotificationSettingContent>
            <AntSwitch checked={notiSetting?.isRecordAlarmSet} onChange={handleChangeOrganizeToday} />
          </div>
          <NotificationTimeWrapper>
            {notiSetting?.recordAlarmTimeCollection?.map((time, index) => (
              <NotificationTime key={index} index={index}>
                <span style={{ fontSize: 12, marginRight: 8 }}>{index === 0 ? `${t('settings.organize.alarm1')}` : `${t('settings.organize.alarm2')}`}</span>
                <SelectWrapper>
                  <Select
                    value={dayjs(time).format(DATE_FORMAT_7)}
                    onChange={(e) => {
                      handleChangeNotiTime(e.target.value, index);
                    }}
                    style={{ width: 75, height: 34, fontSize: '13px' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 350,
                        },
                      },
                    }}
                  >
                    {selectableTime.map((v, idx) => (
                      <MenuItem key={idx} value={v}>
                        <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  {index === 1 && (
                    <TimeDeleteButton className="time-delete-button" onClick={handleClickDeleteTime}>
                      <Icons.Close width={10} height={10} stroke={COLORS.white} />
                    </TimeDeleteButton>
                  )}
                </SelectWrapper>
              </NotificationTime>
            ))}
          </NotificationTimeWrapper>
          {notiSetting?.recordAlarmTimeCollection?.length === 1 && (
            <ExtraNotificationTime onClick={handleClickExtraNotificationTime}>
              <Icons.Plus width={16} height={16} stroke={COLORS.sub2} />
              <span style={{ color: COLORS.sub2, fontSize: '10px', marginLeft: 4 }}>{t('settings.organize.add_alarm')}</span>
            </ExtraNotificationTime>
          )}
        </NotificationSettingWrapper>
      </NotificationWrapper>
    </Container>
  );
};
export default NotificationSetting;

const Container = styled.div`
  .MuiSelect-select {
    display: flex;
    justify-content: center;
    padding: 7px 8px !important;
  }

  .MuiSvgIcon-root {
    display: none;
  }
`;

const NotificationWrapper = styled.div``;

const NotificationTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
`;

const NotificationSettingWrapper = styled.div`
  width: 630px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 24px;
`;

const NotificationSettingContent = styled.div`
  display: flex;
`;

const NotificationSettingCotentText = styled.div`
  margin-left: 8px;
`;

const NotificationTimeWrapper = styled.div`
  margin: 12px 0px 0px 26px;
`;

const NotificationTime = styled.div<{ index?: number }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.index === 1 &&
    css`
      margin-top: 8px;
      position: relative;
    `}
`;

const SelectWrapper = styled.div`
  :hover {
    .time-delete-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const ExtraNotificationTime = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  padding: 4px 6px;
  margin: 12px 0px 0px 20px;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const TimeDeleteButton = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${COLORS.gray900};
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: -5px;
  right: -7px;
  display: none;
`;
